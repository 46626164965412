@charset "UTF-8";
*,
::before,
::after {
  margin: 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
  font-size: 1em;
  font-family: inherit;
  font-weight: inherit;
}

::before,
::after {
  vertical-align: inherit;
  text-decoration: inherit;
}

html {
  font-family: sans-serif;
  word-break: break-word;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
main,
section,
article,
aside,
nav,
details,
menu,
figure,
figcaption {
  display: block;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

ol,
ul {
  padding: 0;
  list-style: none;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

canvas,
audio,
video {
  display: inline-block;
}

img,
svg,
iframe,
canvas,
audio,
video {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  border-radius: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

address {
  font-style: normal;
}

textarea {
  overflow: auto;
  resize: vertical;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

summary {
  display: list-item;
}

table {
  border-collapse: collapse;
}

hr {
  height: 0;
  overflow: visible;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

body {
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans Japanese", Meiryo, "Yu Gothic Medium", sans-serif;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
}

img[src$=".svg"] {
  width: 100%;
}

iframe {
  max-width: 100%;
}

.section > .inner {
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 576px) {
  .section > .inner {
    width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .section > .inner {
    width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .section > .inner {
    width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .section > .inner {
    width: 1140px;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid.is-baseline {
  align-items: baseline;
}

.grid.is-middle {
  align-items: center;
}

.grid.is-bottom {
  align-items: flex-end;
}

.grid.is-center {
  justify-content: center;
}

.grid.is-right {
  justify-content: flex-end;
}

.grid.is-between {
  justify-content: space-between;
}

.grid.is-around {
  justify-content: space-around;
}

.grid.is-reverse {
  flex-direction: row-reverse;
}

.grid.is-stretch > .column {
  display: flex;
}

.grid.is-gap-xxl {
  margin-left: -28px;
  margin-bottom: -28px;
}

.grid.is-gap-xxl > .column {
  padding-left: 28px;
  padding-bottom: 28px;
}

.grid.is-gap-xl {
  margin-left: -24px;
  margin-bottom: -24px;
}

.grid.is-gap-xl > .column {
  padding-left: 24px;
  padding-bottom: 24px;
}

.grid.is-gap-lg {
  margin-left: -20px;
  margin-bottom: -20px;
}

.grid.is-gap-lg > .column {
  padding-left: 20px;
  padding-bottom: 20px;
}

.grid.is-gap, .grid.is-gap-md {
  margin-left: -16px;
  margin-bottom: -16px;
}

.grid.is-gap > .column, .grid.is-gap-md > .column {
  padding-left: 16px;
  padding-bottom: 16px;
}

.grid.is-gap-sm {
  margin-left: -12px;
  margin-bottom: -12px;
}

.grid.is-gap-sm > .column {
  padding-left: 12px;
  padding-bottom: 12px;
}

.grid.is-gap-xs {
  margin-left: -8px;
  margin-bottom: -8px;
}

.grid.is-gap-xs > .column {
  padding-left: 8px;
  padding-bottom: 8px;
}

.grid.is-gap-xxs {
  margin-left: -4px;
  margin-bottom: -4px;
}

.grid.is-gap-xxs > .column {
  padding-left: 4px;
  padding-bottom: 4px;
}

.grid.is-gap-vertical-xxl {
  margin-bottom: -28px;
}

.grid.is-gap-vertical-xxl > .column {
  padding-bottom: 28px;
}

.grid.is-gap-vertical-xl {
  margin-bottom: -24px;
}

.grid.is-gap-vertical-xl > .column {
  padding-bottom: 24px;
}

.grid.is-gap-vertical-lg {
  margin-bottom: -20px;
}

.grid.is-gap-vertical-lg > .column {
  padding-bottom: 20px;
}

.grid.is-gap-vertical, .grid.is-gap-vertical-md {
  margin-bottom: -16px;
}

.grid.is-gap-vertical > .column, .grid.is-gap-vertical-md > .column {
  padding-bottom: 16px;
}

.grid.is-gap-vertical-sm {
  margin-bottom: -12px;
}

.grid.is-gap-vertical-sm > .column {
  padding-bottom: 12px;
}

.grid.is-gap-vertical-xs {
  margin-bottom: -8px;
}

.grid.is-gap-vertical-xs > .column {
  padding-bottom: 8px;
}

.grid.is-gap-vertical-xxs {
  margin-bottom: -4px;
}

.grid.is-gap-vertical-xxs > .column {
  padding-bottom: 4px;
}

.grid.is-gap-horizontal-xxl {
  margin-left: -28px;
}

.grid.is-gap-horizontal-xxl > .column {
  padding-left: 28px;
}

.grid.is-gap-horizontal-xl {
  margin-left: -24px;
}

.grid.is-gap-horizontal-xl > .column {
  padding-left: 24px;
}

.grid.is-gap-horizontal-lg {
  margin-left: -20px;
}

.grid.is-gap-horizontal-lg > .column {
  padding-left: 20px;
}

.grid.is-gap-horizontal, .grid.is-gap-horizontal-md {
  margin-left: -16px;
}

.grid.is-gap-horizontal > .column, .grid.is-gap-horizontal-md > .column {
  padding-left: 16px;
}

.grid.is-gap-horizontal-sm {
  margin-left: -12px;
}

.grid.is-gap-horizontal-sm > .column {
  padding-left: 12px;
}

.grid.is-gap-horizontal-xs {
  margin-left: -8px;
}

.grid.is-gap-horizontal-xs > .column {
  padding-left: 8px;
}

.grid.is-gap-horizontal-xxs {
  margin-left: -4px;
}

.grid.is-gap-horizontal-xxs > .column {
  padding-left: 4px;
}

.card {
  position: relative;
  display: block;
  overflow: hidden;
}

.card.is-bg, .card.is-bg-light {
  background-color: white;
}

.card.is-outline {
  border: 1px solid #d1d8dc;
}

.card.is-disabled, .card[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.card.is-link {
  cursor: pointer;
  transition: ease-out 0.12s;
}

.card.is-link:not(.is-disabled):not([disabled]):hover {
  background-color: whitesmoke;
}

.card.is-link:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5);
  outline: none;
}

.card.is-zoom {
  cursor: pointer;
  transition: ease-out 0.12s;
}

.card.is-zoom:not(.is-disabled):not([disabled]):hover {
  transform: scale(1.025);
}

.card.is-zoom:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5);
  outline: none;
}

.card.is-floating {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.card.is-floating.is-link:hover, .card.is-floating.is-zoom:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

.card.is-floating.is-link:focus, .card.is-floating.is-zoom:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  outline: none;
}

.card.is-radius-xxl {
  border-radius: 16px;
}

.card.is-radius-xl {
  border-radius: 12px;
}

.card.is-radius-lg {
  border-radius: 8px;
}

.card.is-radius, .card.is-radius-md {
  border-radius: 4px;
}

.card.is-radius-sm {
  border-radius: 3px;
}

.card.is-radius-xs {
  border-radius: 2px;
}

.card.is-radius-xxs {
  border-radius: 1px;
}

.box {
  position: relative;
  display: block;
}

.box.is-bg, .box.is-bg-light {
  background-color: white;
}

.box.is-disabled, .box[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.box.is-flex {
  display: flex;
}

.box.is-inline-flex {
  display: inline-flex;
}

.box.is-flex:not(.is-nowrap), .box.is-inline-flex:not(.is-nowrap) {
  flex-wrap: wrap;
}

.box.is-flex.is-baseline, .box.is-inline-flex.is-baseline {
  align-items: baseline;
}

.box.is-flex.is-middle, .box.is-inline-flex.is-middle {
  align-items: center;
}

.box.is-flex.is-bottom, .box.is-inline-flex.is-bottom {
  align-items: flex-end;
}

.box.is-flex.is-center, .box.is-inline-flex.is-center {
  justify-content: center;
}

.box.is-flex.is-right, .box.is-inline-flex.is-right {
  justify-content: flex-end;
}

.box.is-flex.is-between, .box.is-inline-flex.is-between {
  justify-content: space-between;
}

.box.is-flex.is-around, .box.is-inline-flex.is-around {
  justify-content: space-around;
}

.box.is-flex.is-reverse, .box.is-inline-flex.is-reverse {
  flex-direction: row-reverse;
}

.box.is-outline {
  border: 1px solid #d1d8dc;
}

.box.is-outline-top {
  border-top: 1px solid #d1d8dc;
}

.box.is-outline-right {
  border-right: 1px solid #d1d8dc;
}

.box.is-outline-bottom {
  border-bottom: 1px solid #d1d8dc;
}

.box.is-outline-left {
  border-left: 1px solid #d1d8dc;
}

.box.is-separate > *:not(:last-child) {
  border-bottom: 1px solid #d1d8dc;
}

.box.is-separate-parent > *:not(:last-child) .is-separate-child {
  border-bottom: 1px solid #d1d8dc;
}

.box.is-link {
  cursor: pointer;
  transition: ease-out 0.12s;
}

.box.is-link:not(.is-disabled):not([disabled]):hover {
  background-color: whitesmoke;
}

.box.is-angle-right:after, .box.is-angle-left:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0.5em;
  height: 0.5em;
  border-top: 2px solid rgba(0, 0, 0, 0.26);
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  z-index: 2;
}

.box.is-angle-right:after {
  right: 0.75em;
  transform: translateY(-50%) rotate(45deg);
}

.box.is-angle-left:after {
  left: 0.75em;
  transform: translateY(-50%) rotate(-135deg);
}

.box.is-angle-right.is-angle-up:after, .box.is-angle-left.is-angle-up:after {
  transform: translateY(-30%) rotate(-45deg);
}

.box.is-angle-right.is-angle-down:after, .box.is-angle-left.is-angle-down:after {
  transform: translateY(-70%) rotate(135deg);
}

.joint {
  display: flex;
  flex-wrap: nowrap;
}

.joint > .button:not(.is-joint-exclude):not(:last-child),
.joint > .button:not(.is-joint-exclude):not(:last-child) > select,
.joint > .badge:not(.is-joint-exclude):not(:last-child),
.joint > .badge:not(.is-joint-exclude):not(:last-child) > select,
.joint > .input:not(.is-joint-exclude):not(:last-child),
.joint > .input:not(.is-joint-exclude):not(:last-child) > select,
.joint > .select:not(.is-joint-exclude):not(:last-child),
.joint > .select:not(.is-joint-exclude):not(:last-child) > select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.joint > .button:not(.is-joint-exclude):not(:first-child),
.joint > .button:not(.is-joint-exclude):not(:first-child) > select,
.joint > .badge:not(.is-joint-exclude):not(:first-child),
.joint > .badge:not(.is-joint-exclude):not(:first-child) > select,
.joint > .input:not(.is-joint-exclude):not(:first-child),
.joint > .input:not(.is-joint-exclude):not(:first-child) > select,
.joint > .select:not(.is-joint-exclude):not(:first-child),
.joint > .select:not(.is-joint-exclude):not(:first-child) > select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.joint > .button:not(.is-joint-exclude):nth-child(2),
.joint > .badge:not(.is-joint-exclude):nth-child(2),
.joint > .input:not(.is-joint-exclude):nth-child(2),
.joint > .select:not(.is-joint-exclude):nth-child(2) {
  border-left: none;
}

.joint > .button:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude),
.joint > .button:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude)
> select,
.joint > .badge:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude),
.joint > .badge:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude)
> select,
.joint > .input:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude),
.joint > .input:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude)
> select,
.joint > .select:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude),
.joint > .select:not(.is-joint-exclude):nth-child(2) ~ :not(.is-joint-exclude)
> select {
  border-left: none;
}

.joint > .button:not(.is-joint-exclude):focus,
.joint > .button:not(.is-joint-exclude) > select:focus,
.joint > .badge:not(.is-joint-exclude):focus,
.joint > .badge:not(.is-joint-exclude) > select:focus,
.joint > .input:not(.is-joint-exclude):focus,
.joint > .input:not(.is-joint-exclude) > select:focus,
.joint > .select:not(.is-joint-exclude):focus,
.joint > .select:not(.is-joint-exclude) > select:focus {
  z-index: 2;
}

.text.is-dark {
  color: black;
}

.text.is-dark-1 {
  color: rgba(0, 0, 0, 0.87);
}

.text.is-dark-2 {
  color: rgba(0, 0, 0, 0.7);
}

.text.is-dark-3 {
  color: rgba(0, 0, 0, 0.6);
}

.text.is-dark-4 {
  color: rgba(0, 0, 0, 0.5);
}

.text.is-dark-5 {
  color: rgba(0, 0, 0, 0.4);
}

.text.is-dark-6 {
  color: rgba(0, 0, 0, 0.26);
}

.text.is-dark-7 {
  color: rgba(0, 0, 0, 0.15);
}

.text.is-dark-8 {
  color: rgba(0, 0, 0, 0.12);
}

.text.is-dark-9 {
  color: rgba(0, 0, 0, 0.05);
}

.text.is-light {
  color: white;
}

.text.is-light-1 {
  color: rgba(255, 255, 255, 0.87);
}

.text.is-light-2 {
  color: rgba(255, 255, 255, 0.7);
}

.text.is-light-3 {
  color: rgba(255, 255, 255, 0.6);
}

.text.is-light-4 {
  color: rgba(255, 255, 255, 0.5);
}

.text.is-light-5 {
  color: rgba(255, 255, 255, 0.4);
}

.text.is-light-6 {
  color: rgba(255, 255, 255, 0.26);
}

.text.is-light-7 {
  color: rgba(255, 255, 255, 0.15);
}

.text.is-light-8 {
  color: rgba(255, 255, 255, 0.12);
}

.text.is-light-9 {
  color: rgba(255, 255, 255, 0.05);
}

.text.is-primary {
  color: #37b0be;
}

.text.is-info {
  color: #4b9bd8;
}

.text.is-success {
  color: #2ca52c;
}

.text.is-warning {
  color: #ec8313;
}

.text.is-danger {
  color: #db5757;
}

.text.is-normal {
  font-weight: 400;
}

.text.is-strong {
  font-weight: 700;
}

.text.is-weight-100 {
  font-weight: 100;
}

.text.is-weight-200 {
  font-weight: 200;
}

.text.is-weight-300 {
  font-weight: 300;
}

.text.is-weight-400 {
  font-weight: 400;
}

.text.is-weight-500 {
  font-weight: 500;
}

.text.is-weight-600 {
  font-weight: 600;
}

.text.is-weight-700 {
  font-weight: 700;
}

.text.is-weight-800 {
  font-weight: 800;
}

.text.is-weight-900 {
  font-weight: 900;
}

.text.is-line-height-xxl {
  line-height: 2;
}

.text.is-line-height-xl {
  line-height: 1.9;
}

.text.is-line-height-lg {
  line-height: 1.75;
}

.text.is-line-height-md {
  line-height: 1.5;
}

.text.is-line-height-sm {
  line-height: 1.375;
}

.text.is-line-height-xs {
  line-height: 1.25;
}

.text.is-line-height-xxs {
  line-height: 1;
}

.text.is-italic {
  font-style: italic;
}

.text.is-delete {
  text-decoration: line-through;
}

.text.is-capitalize {
  text-transform: capitalize;
}

.text.is-lowercase {
  text-transform: lowercase;
}

.text.is-uppercase {
  text-transform: uppercase;
}

.text.is-right, .text.is-mobile-right {
  text-align: right;
}

.text.is-center, .text.is-mobile-center {
  text-align: center;
}

.text.is-left, .text.is-mobile-left {
  text-align: left;
}

@media screen and (min-width: 576px) {
  .text.is-fablet-right {
    text-align: right;
  }
  .text.is-fablet-center {
    text-align: center;
  }
  .text.is-fablet-left {
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .text.is-tablet-right {
    text-align: right;
  }
  .text.is-tablet-center {
    text-align: center;
  }
  .text.is-tablet-left {
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .text.is-desktop-right {
    text-align: right;
  }
  .text.is-desktop-center {
    text-align: center;
  }
  .text.is-desktop-left {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .text.is-wide-right {
    text-align: right;
  }
  .text.is-wide-center {
    text-align: center;
  }
  .text.is-wide-left {
    text-align: left;
  }
}

.text.is-justify {
  text-align: justify;
}

.text.is-baseline {
  vertical-align: baseline;
}

.text.is-top {
  vertical-align: top;
}

.text.is-middle {
  vertical-align: middle;
}

.text.is-bottom {
  vertical-align: bottom;
}

.text.is-nowrap {
  white-space: nowrap;
}

.text.is-break {
  word-break: break-all;
}

.text.is-link {
  cursor: pointer;
  border-bottom: 1px solid currentColor;
}

.text.is-link:hover {
  border-bottom-color: transparent;
}

.text.is-link-reverse {
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.text.is-link-reverse:hover {
  border-color: currentColor;
}

.text.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}

.text.is-disabled.is-primary, .text.is-disabled.is-info, .text.is-disabled.is-success, .text.is-disabled.is-warning, .text.is-disabled.is-danger {
  color: rgba(0, 0, 0, 0.26);
}

.text.is-disabled.is-link {
  border-bottom-color: transparent;
}

.text.is-disabled.is-link-reverse:hover {
  border-color: transparent;
}

.blockquote {
  background-color: whitesmoke;
  border-left: 0.5em solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 1.5em;
}

:not(.pre) > .code {
  padding: 0.2em 0.5em;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  color: #5d69a8;
  font-size: 87.5%;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", Meiryo, monospace, serif;
  word-break: break-all;
}

.pre {
  background-color: whitesmoke;
  border-radius: 2px;
  color: #5d69a8;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", Meiryo, monospace, serif;
  white-space: pre;
  word-wrap: normal;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.pre > .code {
  display: block;
  padding: 1.25em 1.5em;
}

.button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.5em 1em;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.5;
  text-align: center;
  transition: ease-out 0.12s;
}

.button:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5);
  outline: none;
}

.button.is-strong {
  font-weight: 700;
}

.button.is-round {
  border-radius: 999em;
}

.button.is-floating:not([disabled]), .button.is-floating:not(.is-disabled) {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.button.is-floating:not([disabled]):hover, .button.is-floating:not(.is-disabled):hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

.button.is-floating:not([disabled]):focus, .button.is-floating:not([disabled]):hover:focus, .button.is-floating:not(.is-disabled):focus, .button.is-floating:not(.is-disabled):hover:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

.button[disabled], .button.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.button.is-square {
  width: calc(2.5em + 1px * 2);
  height: calc(2.5em + 1px * 2);
  padding: 0;
  border-radius: 3px;
  line-height: 1;
}

.button.is-circle {
  width: calc(2.5em + 1px * 2);
  height: calc(2.5em + 1px * 2);
  padding: 0;
  border-radius: 999em;
  line-height: 1;
}

.button.is-plain {
  background-color: whitesmoke;
  border: 1px solid transparent;
}

.button.is-plain:hover {
  background-color: #ededed;
}

.button.is-plain.is-primary {
  background-color: #37b0be;
  color: white;
}

.button.is-plain.is-primary:hover {
  background-color: #319eaa;
}

.button.is-plain.is-primary.is-floating {
  box-shadow: 0 2px 6px 0 rgba(55, 176, 190, 0.6);
}

.button.is-plain.is-primary.is-floating:hover {
  box-shadow: 0 4px 12px 0 rgba(55, 176, 190, 0.6);
}

.button.is-plain.is-primary.is-floating:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(55, 176, 190, 0.6);
}

.button.is-plain.is-info {
  background-color: #4b9bd8;
  color: white;
}

.button.is-plain.is-info:hover {
  background-color: #368fd3;
}

.button.is-plain.is-info.is-floating {
  box-shadow: 0 2px 6px 0 rgba(75, 155, 216, 0.6);
}

.button.is-plain.is-info.is-floating:hover {
  box-shadow: 0 4px 12px 0 rgba(75, 155, 216, 0.6);
}

.button.is-plain.is-info.is-floating:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(75, 155, 216, 0.6);
}

.button.is-plain.is-success {
  background-color: #2ca52c;
  color: white;
}

.button.is-plain.is-success:hover {
  background-color: #279127;
}

.button.is-plain.is-success.is-floating {
  box-shadow: 0 2px 6px 0 rgba(44, 165, 44, 0.6);
}

.button.is-plain.is-success.is-floating:hover {
  box-shadow: 0 4px 12px 0 rgba(44, 165, 44, 0.6);
}

.button.is-plain.is-success.is-floating:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(44, 165, 44, 0.6);
}

.button.is-plain.is-warning {
  background-color: #ec8313;
  color: white;
}

.button.is-plain.is-warning:hover {
  background-color: #d47611;
}

.button.is-plain.is-warning.is-floating {
  box-shadow: 0 2px 6px 0 rgba(236, 131, 19, 0.6);
}

.button.is-plain.is-warning.is-floating:hover {
  box-shadow: 0 4px 12px 0 rgba(236, 131, 19, 0.6);
}

.button.is-plain.is-warning.is-floating:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(236, 131, 19, 0.6);
}

.button.is-plain.is-danger {
  background-color: #db5757;
  color: white;
}

.button.is-plain.is-danger:hover {
  background-color: #d74242;
}

.button.is-plain.is-danger.is-floating {
  box-shadow: 0 2px 6px 0 rgba(219, 87, 87, 0.6);
}

.button.is-plain.is-danger.is-floating:hover {
  box-shadow: 0 4px 12px 0 rgba(219, 87, 87, 0.6);
}

.button.is-plain.is-danger.is-floating:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.5), 0 4px 12px 0 rgba(219, 87, 87, 0.6);
}

.button.is-plain[disabled], .button.is-plain[disabled]:hover, .button.is-plain.is-disabled, .button.is-plain.is-disabled:hover {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.26);
}

.button.is-outline {
  background-color: white;
  border: 1px solid #d1d8dc;
}

.button.is-outline:hover {
  background-color: whitesmoke;
}

.button.is-outline.is-primary {
  border-color: #37b0be;
  color: #37b0be;
}

.button.is-outline.is-primary:hover {
  background-color: #37b0be;
  border-color: transparent;
  color: white;
}

.button.is-outline.is-info {
  border-color: #4b9bd8;
  color: #4b9bd8;
}

.button.is-outline.is-info:hover {
  background-color: #4b9bd8;
  border-color: transparent;
  color: white;
}

.button.is-outline.is-success {
  border-color: #2ca52c;
  color: #2ca52c;
}

.button.is-outline.is-success:hover {
  background-color: #2ca52c;
  border-color: transparent;
  color: white;
}

.button.is-outline.is-warning {
  border-color: #ec8313;
  color: #ec8313;
}

.button.is-outline.is-warning:hover {
  background-color: #ec8313;
  border-color: transparent;
  color: white;
}

.button.is-outline.is-danger {
  border-color: #db5757;
  color: #db5757;
}

.button.is-outline.is-danger:hover {
  background-color: #db5757;
  border-color: transparent;
  color: white;
}

.button.is-outline[disabled], .button.is-outline[disabled]:hover, .button.is-outline.is-disabled, .button.is-outline.is-disabled:hover {
  background-color: #bdbdbd;
  border-color: #d1d8dc;
  color: rgba(0, 0, 0, 0.26);
}

.button.is-melt {
  background-color: transparent;
  border: 1px solid transparent;
}

.button.is-melt:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.button.is-melt.is-primary {
  color: #37b0be;
}

.button.is-melt.is-info {
  color: #4b9bd8;
}

.button.is-melt.is-success {
  color: #2ca52c;
}

.button.is-melt.is-warning {
  color: #ec8313;
}

.button.is-melt.is-danger {
  color: #db5757;
}

.button.is-melt[disabled], .button.is-melt[disabled]:hover, .button.is-melt.is-disabled, .button.is-melt.is-disabled:hover {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.button.is-angle-right:after, .button.is-angle-left:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0.5em;
  height: 0.5em;
  border-top: 2px solid rgba(0, 0, 0, 0.26);
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  z-index: 2;
}

.button.is-angle-right:after {
  right: 0.75em;
  transform: translateY(-50%) rotate(45deg);
}

.button.is-angle-left:after {
  left: 0.75em;
  transform: translateY(-50%) rotate(-135deg);
}

.button.is-angle-right.is-angle-up:after, .button.is-angle-left.is-angle-up:after {
  transform: translateY(-30%) rotate(-45deg);
}

.button.is-angle-right.is-angle-down:after, .button.is-angle-left.is-angle-down:after {
  transform: translateY(-70%) rotate(135deg);
}

.button.is-angle-right.is-primary:after, .button.is-angle-right.is-info:after, .button.is-angle-right.is-success:after, .button.is-angle-right.is-warning:after, .button.is-angle-right.is-danger:after, .button.is-angle-left.is-primary:after, .button.is-angle-left.is-info:after, .button.is-angle-left.is-success:after, .button.is-angle-left.is-warning:after, .button.is-angle-left.is-danger:after {
  border-color: currentColor;
}

.badge {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.25em 0.375em;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  line-height: 1.25;
}

.badge.is-strong {
  font-weight: 700;
}

.badge.is-round {
  border-radius: 999em;
}

.badge.is-square {
  width: calc(1.75em + 1px * 2);
  height: calc(1.75em + 1px * 2);
  padding: 0;
  border-radius: 3px;
  line-height: 1;
}

.badge.is-circle {
  width: calc(1.75em + 1px * 2);
  height: calc(1.75em + 1px * 2);
  padding: 0;
  border-radius: 999em;
  line-height: 1;
}

.badge.is-plain {
  background-color: whitesmoke;
  border: 1px solid transparent;
}

.badge.is-plain.is-primary {
  background-color: #37b0be;
  color: white;
}

.badge.is-plain.is-info {
  background-color: #4b9bd8;
  color: white;
}

.badge.is-plain.is-success {
  background-color: #2ca52c;
  color: white;
}

.badge.is-plain.is-warning {
  background-color: #ec8313;
  color: white;
}

.badge.is-plain.is-danger {
  background-color: #db5757;
  color: white;
}

.badge.is-plain.is-disabled {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.26);
}

.badge.is-outline {
  background-color: white;
  border: 1px solid #d1d8dc;
}

.badge.is-outline.is-primary {
  border-color: #37b0be;
  color: #37b0be;
}

.badge.is-outline.is-info {
  border-color: #4b9bd8;
  color: #4b9bd8;
}

.badge.is-outline.is-success {
  border-color: #2ca52c;
  color: #2ca52c;
}

.badge.is-outline.is-warning {
  border-color: #ec8313;
  color: #ec8313;
}

.badge.is-outline.is-danger {
  border-color: #db5757;
  color: #db5757;
}

.badge.is-outline.is-disabled {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.26);
  border-color: #d1d8dc;
}

.list.is-disc, .list.is-circle, .list.is-square, .list.is-kome, .list.is-decimal {
  margin-left: 1.5em;
}

.list.is-disc > .item:not(:first-child), .list.is-circle > .item:not(:first-child), .list.is-square > .item:not(:first-child), .list.is-kome > .item:not(:first-child), .list.is-decimal > .item:not(:first-child) {
  margin-top: 0.25em;
}

.list.is-disc > .item > .list, .list.is-circle > .item > .list, .list.is-square > .item > .list, .list.is-kome > .item > .list, .list.is-decimal > .item > .list {
  margin-top: 0.5em;
}

.list.is-disc > .item {
  display: list-item;
  list-style: disc outside;
}

.list.is-circle > .item {
  display: list-item;
  list-style: circle outside;
}

.list.is-square > .item {
  display: list-item;
  list-style: square outside;
}

.list.is-kome > .item {
  position: relative;
  display: block;
}

.list.is-kome > .item:before {
  content: "\203B";
  position: absolute;
  top: 0;
  left: -1em;
}

.list.is-decimal > .item {
  display: list-item;
  list-style: decimal outside;
}

.list.is-note > .item {
  display: flex;
}

.list.is-note > .item > *:not(:first-child) {
  margin-left: 0.25em;
}

.table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75em 1em;
  vertical-align: top;
}

.table thead
> tr:last-child
> th {
  border-bottom: 1px solid #d1d8dc;
}

.table tr.box {
  display: table-row;
}

.table th.box,
.table td.box {
  display: table-cell;
}

.table.is-line {
  border-top: 1px solid #d1d8dc;
  border-bottom: 1px solid #d1d8dc;
}

.table.is-line > tbody > tr:not(:first-child) {
  border-top: 1px solid #d1d8dc;
}

.table.is-outline {
  border: 1px solid #d1d8dc;
}

.table.is-border {
  border: 1px solid #d1d8dc;
}

.table.is-border th,
.table.is-border td {
  border: 1px solid #d1d8dc;
}

.table tr.is-paint,
.table th.is-paint,
.table td.is-paint {
  background-color: rgba(38, 121, 130, 0.7);
  color: white;
}

.table tr.is-spot,
.table th.is-spot,
.table td.is-spot {
  background-color: rgba(240, 225, 66, 0.45);
}

.table tr.is-danger,
.table th.is-danger,
.table td.is-danger {
  background-color: rgba(219, 87, 87, 0.2);
}

.table.is-stripe thead {
  background-color: rgba(40, 48, 52, 0.05);
}

.table.is-stripe tbody tr:nth-child(even) {
  background-color: rgba(40, 48, 52, 0.05);
}

.table.is-right th,
.table.is-right td {
  text-align: right;
}

.table tr.is-right,
.table th.is-right,
.table td.is-right {
  text-align: right;
}

.table.is-center th,
.table.is-center td {
  text-align: center;
}

.table tr.is-center,
.table th.is-center,
.table td.is-center {
  text-align: center;
}

.table.is-left th,
.table.is-left td {
  text-align: left;
}

.table tr.is-left,
.table th.is-left,
.table td.is-left {
  text-align: left;
}

.table.is-middle th,
.table.is-middle td {
  vertical-align: middle;
}

.table tr.is-middle,
.table th.is-middle,
.table td.is-middle {
  vertical-align: middle;
}

.table.is-fixed {
  table-layout: fixed;
}

.table-wrap {
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(102, 122, 133, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(102, 122, 133, 0.15), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-size: 40px 100%, 40px 100%, 20px 100%, 20px 100%;
  background-attachment: local, local, scroll, scroll;
  background-color: white;
  background-repeat: no-repeat;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-wrap .table {
  background-color: transparent;
}

.input[type="text"], .input[type="search"], .input[type="url"], .input[type="email"], .input[type="tel"], .input[type="password"], .input[type="datetime-local"], .input[type="date"], .input[type="month"], .input[type="week"], .input[type="time"], .input[type="number"] {
  position: relative;
  max-width: 100%;
  height: calc(2.5em + (1px * 2));
  padding: 0.5em 0.75em;
  background-color: white;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.5;
  appearance: none;
  transition: ease-out 0.12s;
}

.input[type="text"]:focus, .input[type="text"][readonly]:focus, .input[type="search"]:focus, .input[type="search"][readonly]:focus, .input[type="url"]:focus, .input[type="url"][readonly]:focus, .input[type="email"]:focus, .input[type="email"][readonly]:focus, .input[type="tel"]:focus, .input[type="tel"][readonly]:focus, .input[type="password"]:focus, .input[type="password"][readonly]:focus, .input[type="datetime-local"]:focus, .input[type="datetime-local"][readonly]:focus, .input[type="date"]:focus, .input[type="date"][readonly]:focus, .input[type="month"]:focus, .input[type="month"][readonly]:focus, .input[type="week"]:focus, .input[type="week"][readonly]:focus, .input[type="time"]:focus, .input[type="time"][readonly]:focus, .input[type="number"]:focus, .input[type="number"][readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.input[type="text"]:placeholder-shown, .input[type="search"]:placeholder-shown, .input[type="url"]:placeholder-shown, .input[type="email"]:placeholder-shown, .input[type="tel"]:placeholder-shown, .input[type="password"]:placeholder-shown, .input[type="datetime-local"]:placeholder-shown, .input[type="date"]:placeholder-shown, .input[type="month"]:placeholder-shown, .input[type="week"]:placeholder-shown, .input[type="time"]:placeholder-shown, .input[type="number"]:placeholder-shown {
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"]::-webkit-input-placeholder, .input[type="search"]::-webkit-input-placeholder, .input[type="url"]::-webkit-input-placeholder, .input[type="email"]::-webkit-input-placeholder, .input[type="tel"]::-webkit-input-placeholder, .input[type="password"]::-webkit-input-placeholder, .input[type="datetime-local"]::-webkit-input-placeholder, .input[type="date"]::-webkit-input-placeholder, .input[type="month"]::-webkit-input-placeholder, .input[type="week"]::-webkit-input-placeholder, .input[type="time"]::-webkit-input-placeholder, .input[type="number"]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"]:-moz-placeholder, .input[type="search"]:-moz-placeholder, .input[type="url"]:-moz-placeholder, .input[type="email"]:-moz-placeholder, .input[type="tel"]:-moz-placeholder, .input[type="password"]:-moz-placeholder, .input[type="datetime-local"]:-moz-placeholder, .input[type="date"]:-moz-placeholder, .input[type="month"]:-moz-placeholder, .input[type="week"]:-moz-placeholder, .input[type="time"]:-moz-placeholder, .input[type="number"]:-moz-placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"]::-moz-placeholder, .input[type="search"]::-moz-placeholder, .input[type="url"]::-moz-placeholder, .input[type="email"]::-moz-placeholder, .input[type="tel"]::-moz-placeholder, .input[type="password"]::-moz-placeholder, .input[type="datetime-local"]::-moz-placeholder, .input[type="date"]::-moz-placeholder, .input[type="month"]::-moz-placeholder, .input[type="week"]::-moz-placeholder, .input[type="time"]::-moz-placeholder, .input[type="number"]::-moz-placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"]:-ms-input-placeholder, .input[type="search"]:-ms-input-placeholder, .input[type="url"]:-ms-input-placeholder, .input[type="email"]:-ms-input-placeholder, .input[type="tel"]:-ms-input-placeholder, .input[type="password"]:-ms-input-placeholder, .input[type="datetime-local"]:-ms-input-placeholder, .input[type="date"]:-ms-input-placeholder, .input[type="month"]:-ms-input-placeholder, .input[type="week"]:-ms-input-placeholder, .input[type="time"]:-ms-input-placeholder, .input[type="number"]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"][disabled], .input[type="search"][disabled], .input[type="url"][disabled], .input[type="email"][disabled], .input[type="tel"][disabled], .input[type="password"][disabled], .input[type="datetime-local"][disabled], .input[type="date"][disabled], .input[type="month"][disabled], .input[type="week"][disabled], .input[type="time"][disabled], .input[type="number"][disabled] {
  cursor: not-allowed;
  background-color: #bdbdbd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.26);
}

.input[type="text"][readonly], .input[type="search"][readonly], .input[type="url"][readonly], .input[type="email"][readonly], .input[type="tel"][readonly], .input[type="password"][readonly], .input[type="datetime-local"][readonly], .input[type="date"][readonly], .input[type="month"][readonly], .input[type="week"][readonly], .input[type="time"][readonly], .input[type="number"][readonly] {
  background-color: whitesmoke;
  border-color: #d1d8dc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.7);
}

.input[type="text"].is-right, .input[type="search"].is-right, .input[type="url"].is-right, .input[type="email"].is-right, .input[type="tel"].is-right, .input[type="password"].is-right, .input[type="datetime-local"].is-right, .input[type="date"].is-right, .input[type="month"].is-right, .input[type="week"].is-right, .input[type="time"].is-right, .input[type="number"].is-right {
  text-align: right;
}

.input[type="text"].is-center, .input[type="search"].is-center, .input[type="url"].is-center, .input[type="email"].is-center, .input[type="tel"].is-center, .input[type="password"].is-center, .input[type="datetime-local"].is-center, .input[type="date"].is-center, .input[type="month"].is-center, .input[type="week"].is-center, .input[type="time"].is-center, .input[type="number"].is-center {
  text-align: center;
}

.input[type="text"].is-left, .input[type="search"].is-left, .input[type="url"].is-left, .input[type="email"].is-left, .input[type="tel"].is-left, .input[type="password"].is-left, .input[type="datetime-local"].is-left, .input[type="date"].is-left, .input[type="month"].is-left, .input[type="week"].is-left, .input[type="time"].is-left, .input[type="number"].is-left {
  text-align: left;
}

.input[type="text"].is-success:not([disabled]):not([readonly]), .input[type="search"].is-success:not([disabled]):not([readonly]), .input[type="url"].is-success:not([disabled]):not([readonly]), .input[type="email"].is-success:not([disabled]):not([readonly]), .input[type="tel"].is-success:not([disabled]):not([readonly]), .input[type="password"].is-success:not([disabled]):not([readonly]), .input[type="datetime-local"].is-success:not([disabled]):not([readonly]), .input[type="date"].is-success:not([disabled]):not([readonly]), .input[type="month"].is-success:not([disabled]):not([readonly]), .input[type="week"].is-success:not([disabled]):not([readonly]), .input[type="time"].is-success:not([disabled]):not([readonly]), .input[type="number"].is-success:not([disabled]):not([readonly]) {
  border-color: #7edd7e;
}

.input[type="text"].is-danger:not([disabled]):not([readonly]), .input[type="search"].is-danger:not([disabled]):not([readonly]), .input[type="url"].is-danger:not([disabled]):not([readonly]), .input[type="email"].is-danger:not([disabled]):not([readonly]), .input[type="tel"].is-danger:not([disabled]):not([readonly]), .input[type="password"].is-danger:not([disabled]):not([readonly]), .input[type="datetime-local"].is-danger:not([disabled]):not([readonly]), .input[type="date"].is-danger:not([disabled]):not([readonly]), .input[type="month"].is-danger:not([disabled]):not([readonly]), .input[type="week"].is-danger:not([disabled]):not([readonly]), .input[type="time"].is-danger:not([disabled]):not([readonly]), .input[type="number"].is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: #eeafaf;
}

.textarea {
  position: relative;
  max-width: 100%;
  padding: calc(0.5em - 1.5px) 0.5em;
  background-color: white;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.7);
  appearance: none;
  transition: ease-out 0.12s;
}

.textarea:focus, .textarea[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.textarea:placeholder-shown {
  color: rgba(0, 0, 0, 0.26);
}

.textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}

.textarea:-moz-placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 0.26);
}

.textarea::-moz-placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 0.26);
}

.textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.26);
}

.textarea[disabled] {
  cursor: not-allowed;
  background-color: #bdbdbd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.26);
}

.textarea[readonly] {
  background-color: whitesmoke;
  border-color: #d1d8dc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: rgba(0, 0, 0, 0.7);
}

.textarea.is-success:not([disabled]):not([readonly]) {
  border-color: #7edd7e;
}

.textarea.is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: #eeafaf;
}

.input[type="text"].is-round, .input[type="search"].is-round, .input[type="url"].is-round, .input[type="email"].is-round, .input[type="tel"].is-round, .input[type="password"].is-round, .input[type="datetime-local"].is-round, .input[type="date"].is-round, .input[type="month"].is-round, .input[type="week"].is-round, .input[type="time"].is-round, .input[type="number"].is-round {
  border-radius: 999em;
}

.input[type="text"].is-plain, .input[type="search"].is-plain, .input[type="url"].is-plain, .input[type="email"].is-plain, .input[type="tel"].is-plain, .input[type="password"].is-plain, .input[type="datetime-local"].is-plain, .input[type="date"].is-plain, .input[type="month"].is-plain, .input[type="week"].is-plain, .input[type="time"].is-plain, .input[type="number"].is-plain {
  background-color: whitesmoke;
  border: 1px solid transparent;
  box-shadow: none;
}

.input[type="text"].is-plain:focus, .input[type="text"].is-plain[readonly]:focus, .input[type="search"].is-plain:focus, .input[type="search"].is-plain[readonly]:focus, .input[type="url"].is-plain:focus, .input[type="url"].is-plain[readonly]:focus, .input[type="email"].is-plain:focus, .input[type="email"].is-plain[readonly]:focus, .input[type="tel"].is-plain:focus, .input[type="tel"].is-plain[readonly]:focus, .input[type="password"].is-plain:focus, .input[type="password"].is-plain[readonly]:focus, .input[type="datetime-local"].is-plain:focus, .input[type="datetime-local"].is-plain[readonly]:focus, .input[type="date"].is-plain:focus, .input[type="date"].is-plain[readonly]:focus, .input[type="month"].is-plain:focus, .input[type="month"].is-plain[readonly]:focus, .input[type="week"].is-plain:focus, .input[type="week"].is-plain[readonly]:focus, .input[type="time"].is-plain:focus, .input[type="time"].is-plain[readonly]:focus, .input[type="number"].is-plain:focus, .input[type="number"].is-plain[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
}

.input[type="text"].is-plain[disabled], .input[type="search"].is-plain[disabled], .input[type="url"].is-plain[disabled], .input[type="email"].is-plain[disabled], .input[type="tel"].is-plain[disabled], .input[type="password"].is-plain[disabled], .input[type="datetime-local"].is-plain[disabled], .input[type="date"].is-plain[disabled], .input[type="month"].is-plain[disabled], .input[type="week"].is-plain[disabled], .input[type="time"].is-plain[disabled], .input[type="number"].is-plain[disabled] {
  background-color: #bdbdbd;
}

.input[type="text"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="search"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="url"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="email"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="tel"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="password"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="datetime-local"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="date"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="month"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="week"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="time"].is-plain.is-success:not([disabled]):not([readonly]), .input[type="number"].is-plain.is-success:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.input[type="text"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="search"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="url"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="email"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="tel"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="password"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="datetime-local"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="date"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="month"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="week"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="time"].is-plain.is-danger:not([disabled]):not([readonly]), .input[type="number"].is-plain.is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.textarea.is-plain {
  background-color: whitesmoke;
  border: 1px solid transparent;
  box-shadow: none;
}

.textarea.is-plain:focus, .textarea.is-plain[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
}

.textarea.is-plain[disabled] {
  background-color: #bdbdbd;
}

.textarea.is-plain.is-success:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.textarea.is-plain.is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.input[type="text"].is-underline, .input[type="search"].is-underline, .input[type="url"].is-underline, .input[type="email"].is-underline, .input[type="tel"].is-underline, .input[type="password"].is-underline, .input[type="datetime-local"].is-underline, .input[type="date"].is-underline, .input[type="month"].is-underline, .input[type="week"].is-underline, .input[type="time"].is-underline, .input[type="number"].is-underline {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d1d8dc;
  border-radius: 0;
  box-shadow: none;
}

.input[type="text"].is-underline:focus, .input[type="text"].is-underline[readonly]:focus, .input[type="search"].is-underline:focus, .input[type="search"].is-underline[readonly]:focus, .input[type="url"].is-underline:focus, .input[type="url"].is-underline[readonly]:focus, .input[type="email"].is-underline:focus, .input[type="email"].is-underline[readonly]:focus, .input[type="tel"].is-underline:focus, .input[type="tel"].is-underline[readonly]:focus, .input[type="password"].is-underline:focus, .input[type="password"].is-underline[readonly]:focus, .input[type="datetime-local"].is-underline:focus, .input[type="datetime-local"].is-underline[readonly]:focus, .input[type="date"].is-underline:focus, .input[type="date"].is-underline[readonly]:focus, .input[type="month"].is-underline:focus, .input[type="month"].is-underline[readonly]:focus, .input[type="week"].is-underline:focus, .input[type="week"].is-underline[readonly]:focus, .input[type="time"].is-underline:focus, .input[type="time"].is-underline[readonly]:focus, .input[type="number"].is-underline:focus, .input[type="number"].is-underline[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: none;
}

.input[type="text"].is-underline[disabled], .input[type="text"].is-underline[readonly], .input[type="search"].is-underline[disabled], .input[type="search"].is-underline[readonly], .input[type="url"].is-underline[disabled], .input[type="url"].is-underline[readonly], .input[type="email"].is-underline[disabled], .input[type="email"].is-underline[readonly], .input[type="tel"].is-underline[disabled], .input[type="tel"].is-underline[readonly], .input[type="password"].is-underline[disabled], .input[type="password"].is-underline[readonly], .input[type="datetime-local"].is-underline[disabled], .input[type="datetime-local"].is-underline[readonly], .input[type="date"].is-underline[disabled], .input[type="date"].is-underline[readonly], .input[type="month"].is-underline[disabled], .input[type="month"].is-underline[readonly], .input[type="week"].is-underline[disabled], .input[type="week"].is-underline[readonly], .input[type="time"].is-underline[disabled], .input[type="time"].is-underline[readonly], .input[type="number"].is-underline[disabled], .input[type="number"].is-underline[readonly] {
  background-color: transparent;
}

.input[type="text"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="search"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="url"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="email"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="tel"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="password"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="datetime-local"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="date"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="month"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="week"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="time"].is-underline.is-success:not([disabled]):not([readonly]), .input[type="number"].is-underline.is-success:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #7edd7e;
}

.input[type="text"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="search"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="url"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="email"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="tel"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="password"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="datetime-local"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="date"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="month"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="week"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="time"].is-underline.is-danger:not([disabled]):not([readonly]), .input[type="number"].is-underline.is-danger:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #eeafaf;
}

.textarea.is-underline {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d1d8dc;
  border-radius: 0;
  box-shadow: none;
}

.textarea.is-underline:focus, .textarea.is-underline[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: none;
}

.textarea.is-underline[disabled], .textarea.is-underline[readonly] {
  background-color: transparent;
}

.textarea.is-underline.is-success:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #7edd7e;
}

.textarea.is-underline.is-danger:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #eeafaf;
}

.input[type="text"].is-inside, .input[type="search"].is-inside, .input[type="url"].is-inside, .input[type="email"].is-inside, .input[type="tel"].is-inside, .input[type="password"].is-inside, .input[type="datetime-local"].is-inside, .input[type="date"].is-inside, .input[type="month"].is-inside, .input[type="week"].is-inside, .input[type="time"].is-inside, .input[type="number"].is-inside {
  padding: 0.5em 0.75em;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.input[type="text"].is-inside:focus, .input[type="text"].is-inside[readonly]:focus, .input[type="search"].is-inside:focus, .input[type="search"].is-inside[readonly]:focus, .input[type="url"].is-inside:focus, .input[type="url"].is-inside[readonly]:focus, .input[type="email"].is-inside:focus, .input[type="email"].is-inside[readonly]:focus, .input[type="tel"].is-inside:focus, .input[type="tel"].is-inside[readonly]:focus, .input[type="password"].is-inside:focus, .input[type="password"].is-inside[readonly]:focus, .input[type="datetime-local"].is-inside:focus, .input[type="datetime-local"].is-inside[readonly]:focus, .input[type="date"].is-inside:focus, .input[type="date"].is-inside[readonly]:focus, .input[type="month"].is-inside:focus, .input[type="month"].is-inside[readonly]:focus, .input[type="week"].is-inside:focus, .input[type="week"].is-inside[readonly]:focus, .input[type="time"].is-inside:focus, .input[type="time"].is-inside[readonly]:focus, .input[type="number"].is-inside:focus, .input[type="number"].is-inside[readonly]:focus {
  border-color: transparent;
  box-shadow: none;
}

.input[type="text"].is-inside[disabled], .input[type="text"].is-inside[readonly], .input[type="search"].is-inside[disabled], .input[type="search"].is-inside[readonly], .input[type="url"].is-inside[disabled], .input[type="url"].is-inside[readonly], .input[type="email"].is-inside[disabled], .input[type="email"].is-inside[readonly], .input[type="tel"].is-inside[disabled], .input[type="tel"].is-inside[readonly], .input[type="password"].is-inside[disabled], .input[type="password"].is-inside[readonly], .input[type="datetime-local"].is-inside[disabled], .input[type="datetime-local"].is-inside[readonly], .input[type="date"].is-inside[disabled], .input[type="date"].is-inside[readonly], .input[type="month"].is-inside[disabled], .input[type="month"].is-inside[readonly], .input[type="week"].is-inside[disabled], .input[type="week"].is-inside[readonly], .input[type="time"].is-inside[disabled], .input[type="time"].is-inside[readonly], .input[type="number"].is-inside[disabled], .input[type="number"].is-inside[readonly] {
  background-color: transparent;
  border-color: transparent;
}

.input[type="text"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="search"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="url"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="email"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="tel"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="password"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="datetime-local"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="date"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="month"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="week"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="time"].is-inside.is-success:not([disabled]):not([readonly]), .input[type="number"].is-inside.is-success:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.input[type="text"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="search"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="url"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="email"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="tel"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="password"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="datetime-local"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="date"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="month"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="week"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="time"].is-inside.is-danger:not([disabled]):not([readonly]), .input[type="number"].is-inside.is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.textarea.is-inside {
  padding: 0.5em 0.75em;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.textarea.is-inside:focus, .textarea.is-inside[readonly]:focus {
  border-color: transparent;
  box-shadow: none;
}

.textarea.is-inside[disabled], .textarea.is-inside[readonly] {
  background-color: transparent;
  border-color: transparent;
}

.textarea.is-inside.is-success:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.textarea.is-inside.is-danger:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.input[type="file"] {
  display: none;
}

.label {
  cursor: pointer;
  display: flex;
}

.label:not(.is-middle) .radio {
  top: 0.25em;
}

.label:not(.is-middle) .checkbox {
  top: 0.25em;
}

.label.is-middle {
  align-items: center;
}

.label.is-center {
  justify-content: center;
}

.radio {
  position: relative;
  display: inline-block;
  flex: none;
  width: 1em;
  height: 1em;
  background-color: white;
  border: 2px solid #d1d8dc;
  border-radius: 0.5em;
  transition: ease-out 0.12s;
}

.radio:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5em;
  height: 0.5em;
  background-color: #37b0be;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
  transition: ease-out 0.12s;
  opacity: 0;
}

.input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.radio:focus {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.input[type="radio"]:focus + .label .radio {
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.radio.is-checked {
  border-color: #37b0be;
}

.radio.is-checked:before {
  opacity: 1;
}

.input[type="radio"]:checked + .label .radio {
  border-color: #37b0be;
}

.input[type="radio"]:checked + .label .radio:before {
  opacity: 1;
}

.radio.is-disabled {
  cursor: not-allowed;
  border-color: #eef0f2;
}

.input[type="radio"][disabled] + .label {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
}

.input[type="radio"][disabled] + .label .radio {
  cursor: not-allowed;
  border-color: #eef0f2;
}

.checkbox {
  position: relative;
  display: inline-block;
  flex: none;
  width: 1em;
  height: 1em;
  background-color: white;
  border: 2px solid #d1d8dc;
  border-radius: 2px;
  transition: ease-out 0.12s;
}

.checkbox:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.75em;
  height: 0.375em;
  border-left: 0.125em solid white;
  border-bottom: 0.125em solid white;
  transform: translate(-50%, -70%) rotate(-45deg);
  transition: ease-out 0.12s;
  opacity: 0;
}

.input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox:focus {
  border-color: #0f7dd2;
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.input[type="checkbox"]:focus + .label .checkbox {
  border-color: #0f7dd2;
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.checkbox.is-checked {
  background-color: #37b0be;
  border-color: #37b0be;
}

.checkbox.is-checked:before {
  opacity: 1;
}

.input[type="checkbox"]:checked + .label .checkbox {
  background-color: #37b0be;
  border-color: #37b0be;
}

.input[type="checkbox"]:checked + .label .checkbox:before {
  opacity: 1;
}

.checkbox.is-disabled {
  cursor: not-allowed;
  border-color: #eef0f2;
}

.input[type="checkbox"][disabled] + .label {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
}

.input[type="checkbox"][disabled] + .label .checkbox {
  cursor: not-allowed;
  border-color: #eef0f2;
}

.select {
  position: relative;
  display: inline-flex;
}

.select > select {
  cursor: pointer;
  flex: none;
  width: 100%;
  height: calc(2.5em + (1px * 2));
  padding: 0.5em 0.75em;
  background-color: white;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.5;
  transition: ease-out 0.12s;
  appearance: none;
}

.select > select::-ms-expand {
  display: none;
}

.select > select:focus, .select > select[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: 0 0 0 4px rgba(36, 151, 240, 0.36);
  outline: none;
}

.select > select[disabled] {
  cursor: not-allowed;
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.26);
}

.select > select[disabled] > option {
  color: rgba(0, 0, 0, 0.26);
}

.select > select[readonly] {
  background-color: whitesmoke;
  color: rgba(0, 0, 0, 0.7);
}

.select:not(.is-multiple):after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 1em;
  width: 0.5em;
  height: 0.5em;
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  transform: translateY(-70%) rotate(45deg);
  z-index: 3;
}

.select:not(.is-multiple) > select {
  padding-right: 2.5em;
}

.select.is-success > select:not([disabled]):not([readonly]) {
  border-color: #7edd7e;
}

.select.is-danger > select:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: #eeafaf;
}

.select.is-round:not(.is-multiple) > select {
  border-radius: 999em;
}

.select.is-plain > select {
  background-color: whitesmoke;
  border: 1px solid transparent;
}

.select.is-plain > select:focus, .select.is-plain > select[readonly]:focus {
  border-color: #0f7dd2;
}

.select.is-plain > select[disabled] {
  background-color: #bdbdbd;
}

.select.is-plain.is-success > select:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.select.is-plain.is-danger > select:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.select.is-underline > select {
  padding-left: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d1d8dc;
  border-radius: 0;
  box-shadow: none;
}

.select.is-underline > select:focus, .select.is-underline > select[readonly]:focus {
  border-color: #0f7dd2;
  box-shadow: none;
}

.select.is-underline > select[disabled], .select.is-underline > select[readonly] {
  background-color: transparent;
}

.select.is-underline.is-success > select:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #7edd7e;
}

.select.is-underline.is-danger > select:not([disabled]):not([readonly]) {
  background-color: transparent;
  border-bottom-color: #eeafaf;
}

.select.is-inside > select {
  padding: 0.5em 0.75em;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.select.is-inside > select:focus, .select.is-inside > select[readonly]:focus {
  border-color: transparent;
  box-shadow: none;
}

.select.is-inside > select[disabled], .select.is-inside > select[readonly] {
  background-color: transparent;
  border-color: transparent;
}

.select.is-inside:not(.is-multiple) > select {
  padding-right: 2.5em;
}

.select.is-inside.is-success > select:not([disabled]):not([readonly]) {
  background-color: #dbf5db;
  border-color: transparent;
}

.select.is-inside.is-danger > select:not([disabled]):not([readonly]) {
  background-color: #fbeaea;
  border-color: transparent;
}

.select.is-multiple > select {
  height: auto;
  padding: 0;
}

.select.is-multiple > select > option {
  min-height: 1.5em;
  padding: 0.5em 0.75em;
}

.fieldset {
  padding: 0;
  border: none;
}

.iframe {
  position: relative;
  display: block;
  width: 100%;
}

.iframe:before {
  content: "";
  display: block;
}

.iframe.is-aspect-16x9:before {
  padding-top: 56.25%;
}

.iframe.is-aspect-4x3:before {
  padding-top: 75%;
}

.iframe > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icon, .im, .fa, .fas, .far, .fal, .fab, .material-icons {
  font-size: inherit;
  line-height: inherit;
}

svg.icon {
  display: inline-block;
  flex: none;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
}

.material-icons {
  vertical-align: bottom;
}

.icon.is-dark, .im.is-dark, .fa.is-dark, .fas.is-dark, .far.is-dark, .fal.is-dark, .fab.is-dark, .material-icons.is-dark {
  color: black;
}

.icon.is-dark-1, .im.is-dark-1, .fa.is-dark-1, .fas.is-dark-1, .far.is-dark-1, .fal.is-dark-1, .fab.is-dark-1, .material-icons.is-dark-1 {
  color: rgba(0, 0, 0, 0.87);
}

.icon.is-dark-2, .im.is-dark-2, .fa.is-dark-2, .fas.is-dark-2, .far.is-dark-2, .fal.is-dark-2, .fab.is-dark-2, .material-icons.is-dark-2 {
  color: rgba(0, 0, 0, 0.7);
}

.icon.is-dark-3, .im.is-dark-3, .fa.is-dark-3, .fas.is-dark-3, .far.is-dark-3, .fal.is-dark-3, .fab.is-dark-3, .material-icons.is-dark-3 {
  color: rgba(0, 0, 0, 0.6);
}

.icon.is-dark-4, .im.is-dark-4, .fa.is-dark-4, .fas.is-dark-4, .far.is-dark-4, .fal.is-dark-4, .fab.is-dark-4, .material-icons.is-dark-4 {
  color: rgba(0, 0, 0, 0.5);
}

.icon.is-dark-5, .im.is-dark-5, .fa.is-dark-5, .fas.is-dark-5, .far.is-dark-5, .fal.is-dark-5, .fab.is-dark-5, .material-icons.is-dark-5 {
  color: rgba(0, 0, 0, 0.4);
}

.icon.is-dark-6, .im.is-dark-6, .fa.is-dark-6, .fas.is-dark-6, .far.is-dark-6, .fal.is-dark-6, .fab.is-dark-6, .material-icons.is-dark-6 {
  color: rgba(0, 0, 0, 0.26);
}

.icon.is-dark-7, .im.is-dark-7, .fa.is-dark-7, .fas.is-dark-7, .far.is-dark-7, .fal.is-dark-7, .fab.is-dark-7, .material-icons.is-dark-7 {
  color: rgba(0, 0, 0, 0.15);
}

.icon.is-dark-8, .im.is-dark-8, .fa.is-dark-8, .fas.is-dark-8, .far.is-dark-8, .fal.is-dark-8, .fab.is-dark-8, .material-icons.is-dark-8 {
  color: rgba(0, 0, 0, 0.12);
}

.icon.is-dark-9, .im.is-dark-9, .fa.is-dark-9, .fas.is-dark-9, .far.is-dark-9, .fal.is-dark-9, .fab.is-dark-9, .material-icons.is-dark-9 {
  color: rgba(0, 0, 0, 0.05);
}

.icon.is-light, .im.is-light, .fa.is-light, .fas.is-light, .far.is-light, .fal.is-light, .fab.is-light, .material-icons.is-light {
  color: white;
}

.icon.is-light-1, .im.is-light-1, .fa.is-light-1, .fas.is-light-1, .far.is-light-1, .fal.is-light-1, .fab.is-light-1, .material-icons.is-light-1 {
  color: rgba(255, 255, 255, 0.87);
}

.icon.is-light-2, .im.is-light-2, .fa.is-light-2, .fas.is-light-2, .far.is-light-2, .fal.is-light-2, .fab.is-light-2, .material-icons.is-light-2 {
  color: rgba(255, 255, 255, 0.7);
}

.icon.is-light-3, .im.is-light-3, .fa.is-light-3, .fas.is-light-3, .far.is-light-3, .fal.is-light-3, .fab.is-light-3, .material-icons.is-light-3 {
  color: rgba(255, 255, 255, 0.6);
}

.icon.is-light-4, .im.is-light-4, .fa.is-light-4, .fas.is-light-4, .far.is-light-4, .fal.is-light-4, .fab.is-light-4, .material-icons.is-light-4 {
  color: rgba(255, 255, 255, 0.5);
}

.icon.is-light-5, .im.is-light-5, .fa.is-light-5, .fas.is-light-5, .far.is-light-5, .fal.is-light-5, .fab.is-light-5, .material-icons.is-light-5 {
  color: rgba(255, 255, 255, 0.4);
}

.icon.is-light-6, .im.is-light-6, .fa.is-light-6, .fas.is-light-6, .far.is-light-6, .fal.is-light-6, .fab.is-light-6, .material-icons.is-light-6 {
  color: rgba(255, 255, 255, 0.26);
}

.icon.is-light-7, .im.is-light-7, .fa.is-light-7, .fas.is-light-7, .far.is-light-7, .fal.is-light-7, .fab.is-light-7, .material-icons.is-light-7 {
  color: rgba(255, 255, 255, 0.15);
}

.icon.is-light-8, .im.is-light-8, .fa.is-light-8, .fas.is-light-8, .far.is-light-8, .fal.is-light-8, .fab.is-light-8, .material-icons.is-light-8 {
  color: rgba(255, 255, 255, 0.12);
}

.icon.is-light-9, .im.is-light-9, .fa.is-light-9, .fas.is-light-9, .far.is-light-9, .fal.is-light-9, .fab.is-light-9, .material-icons.is-light-9 {
  color: rgba(255, 255, 255, 0.05);
}

.icon.is-primary, .im.is-primary, .fa.is-primary, .fas.is-primary, .far.is-primary, .fal.is-primary, .fab.is-primary, .material-icons.is-primary {
  color: #37b0be;
}

.icon.is-info, .im.is-info, .fa.is-info, .fas.is-info, .far.is-info, .fal.is-info, .fab.is-info, .material-icons.is-info {
  color: #4b9bd8;
}

.icon.is-success, .im.is-success, .fa.is-success, .fas.is-success, .far.is-success, .fal.is-success, .fab.is-success, .material-icons.is-success {
  color: #2ca52c;
}

.icon.is-warning, .im.is-warning, .fa.is-warning, .fas.is-warning, .far.is-warning, .fal.is-warning, .fab.is-warning, .material-icons.is-warning {
  color: #ec8313;
}

.icon.is-danger, .im.is-danger, .fa.is-danger, .fas.is-danger, .far.is-danger, .fal.is-danger, .fab.is-danger, .material-icons.is-danger {
  color: #db5757;
}

.icon.is-fit, .im.is-fit, .fa.is-fit, .fas.is-fit, .far.is-fit, .fal.is-fit, .fab.is-fit, .material-icons.is-fit {
  width: 1em;
  text-align: center;
}

.alert {
  position: relative;
  padding: 0.675em 0.875em;
  background-color: whitesmoke;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
}

.alert.is-primary {
  background-color: #ebf8f9;
  border-color: #9cdbe2;
}

.alert.is-info {
  background-color: #eaf3fa;
  border-color: #abd0ed;
}

.alert.is-success {
  background-color: #e7f9e7;
  border-color: #8ae08a;
}

.alert.is-warning {
  background-color: #fdf0e3;
  border-color: #f5bf84;
}

.alert.is-danger {
  background-color: #fbeeee;
  border-color: #f0b7b7;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  font-weight: 700;
}

.wysiwyg h1 {
  font-size: 200%;
}

.wysiwyg h2 {
  font-size: 150%;
}

.wysiwyg h3 {
  font-size: 125%;
}

.wysiwyg h4 {
  font-size: 100%;
}

.wysiwyg h5 {
  font-size: 87.5%;
}

.wysiwyg h6 {
  font-size: 75%;
}

.wysiwyg p {
  line-height: 1.9;
}

.wysiwyg small {
  font-size: 87.5%;
}

.wysiwyg strong {
  font-weight: 700;
}

.wysiwyg em {
  font-style: italic;
}

.wysiwyg del, .wysiwyg s {
  text-decoration: line-through;
}

.wysiwyg a {
  cursor: pointer;
  color: #37b0be;
  border-bottom: 1px solid currentColor;
}

.wysiwyg a:hover, .wysiwyg a:active {
  border-bottom-color: transparent;
}

.wysiwyg.is-link-reverse a {
  border-bottom-color: transparent;
}

.wysiwyg.is-link-reverse a:hover, .wysiwyg.is-link-reverse a:active {
  border-bottom-color: currentColor;
}

.wysiwyg blockquote {
  background-color: whitesmoke;
  border-left: 0.5em solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 1.5em;
}

.wysiwyg :not(pre) > code {
  padding: 0.2em 0.5em;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  color: #5d69a8;
  font-size: 87.5%;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", Meiryo, monospace, serif;
  word-break: break-all;
}

.wysiwyg pre {
  background-color: whitesmoke;
  border-radius: 2px;
  color: #5d69a8;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", Meiryo, monospace, serif;
  white-space: pre;
  word-wrap: normal;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.wysiwyg pre > code {
  display: block;
  padding: 1.25em 1.5em;
}

.wysiwyg ul,
.wysiwyg ol {
  line-height: 1.9;
  margin-left: 1.5em;
}

.wysiwyg ul > li,
.wysiwyg ol > li {
  display: list-item;
}

.wysiwyg ul > li li,
.wysiwyg ol > li li {
  font-size: 100%;
}

.wysiwyg ul > li {
  list-style: circle outside;
}

.wysiwyg ol > li {
  list-style: decimal outside;
}

.wysiwyg table {
  width: 100%;
  background-color: white;
}

.wysiwyg table th {
  background-color: rgba(38, 121, 130, 0.7);
  color: white;
  font-weight: 400;
}

.wysiwyg table th,
.wysiwyg table td {
  padding: 0.75em 1em;
  border: 1px solid #d1d8dc;
}

.wysiwyg dl {
  width: 100%;
  background-color: white;
  border: 1px solid #d1d8dc;
  border-top: none;
}

@media screen and (min-width: 576px) {
  .wysiwyg dl {
    display: flex;
    flex-wrap: wrap;
  }
}

.wysiwyg dt {
  width: 100%;
  padding: 0.75em 1em;
  background-color: rgba(38, 121, 130, 0.7);
  border-top: 1px solid #d1d8dc;
  color: white;
  font-weight: 400;
}

@media screen and (min-width: 576px) {
  .wysiwyg dt {
    width: 30%;
  }
}

.wysiwyg dd {
  width: 100%;
  padding: 0.75em 1em;
  border-top: 1px solid #d1d8dc;
}

@media screen and (min-width: 576px) {
  .wysiwyg dd {
    width: 70%;
    border-left: 1px solid #d1d8dc;
  }
}

.wysiwyg hr {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #d1d8dc;
}

.wysiwyg figure {
  text-align: center;
}

.wysiwyg figure > figcaption {
  margin-top: 12px;
  font-size: 87.5%;
}

.is-block,
.is-mobile-block {
  display: block;
}

.is-inline,
.is-mobile-inline {
  display: inline;
}

.is-inline-block,
.is-mobile-inline-block {
  display: inline-block;
}

.is-none,
.is-mobile-none {
  display: none;
}

@media screen and (min-width: 576px) {
  .is-fablet-block {
    display: block;
  }
  .is-fablet-inline {
    display: inline;
  }
  .is-fablet-inline-block {
    display: inline-block;
  }
  .is-fablet-none {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .is-tablet-block {
    display: block;
  }
  .is-tablet-inline {
    display: inline;
  }
  .is-tablet-inline-block {
    display: inline-block;
  }
  .is-tablet-none {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .is-desktop-block {
    display: block;
  }
  .is-desktop-inline {
    display: inline;
  }
  .is-desktop-inline-block {
    display: inline-block;
  }
  .is-desktop-none {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .is-wide-block {
    display: block;
  }
  .is-wide-inline {
    display: inline;
  }
  .is-wide-inline-block {
    display: inline-block;
  }
  .is-wide-none {
    display: none;
  }
}

.is-xxl,
.is-mobile-xxl {
  font-size: 200%;
}

.is-xl,
.is-mobile-xl {
  font-size: 150%;
}

.is-lg,
.is-mobile-lg {
  font-size: 125%;
}

.is-md,
.is-mobile-md {
  font-size: 100%;
}

.is-sm,
.is-mobile-sm {
  font-size: 87.5%;
}

.is-xs,
.is-mobile-xs {
  font-size: 75%;
}

.is-xxs,
.is-mobile-xxs {
  font-size: 62.5%;
}

@media screen and (min-width: 576px) {
  .is-fablet-xxl {
    font-size: 200%;
  }
  .is-fablet-xl {
    font-size: 150%;
  }
  .is-fablet-lg {
    font-size: 125%;
  }
  .is-fablet-md {
    font-size: 100%;
  }
  .is-fablet-sm {
    font-size: 87.5%;
  }
  .is-fablet-xs {
    font-size: 75%;
  }
  .is-fablet-xxs {
    font-size: 62.5%;
  }
}

@media screen and (min-width: 768px) {
  .is-tablet-xxl {
    font-size: 200%;
  }
  .is-tablet-xl {
    font-size: 150%;
  }
  .is-tablet-lg {
    font-size: 125%;
  }
  .is-tablet-md {
    font-size: 100%;
  }
  .is-tablet-sm {
    font-size: 87.5%;
  }
  .is-tablet-xs {
    font-size: 75%;
  }
  .is-tablet-xxs {
    font-size: 62.5%;
  }
}

@media screen and (min-width: 992px) {
  .is-desktop-xxl {
    font-size: 200%;
  }
  .is-desktop-xl {
    font-size: 150%;
  }
  .is-desktop-lg {
    font-size: 125%;
  }
  .is-desktop-md {
    font-size: 100%;
  }
  .is-desktop-sm {
    font-size: 87.5%;
  }
  .is-desktop-xs {
    font-size: 75%;
  }
  .is-desktop-xxs {
    font-size: 62.5%;
  }
}

@media screen and (min-width: 1200px) {
  .is-wide-xxl {
    font-size: 200%;
  }
  .is-wide-xl {
    font-size: 150%;
  }
  .is-wide-lg {
    font-size: 125%;
  }
  .is-wide-md {
    font-size: 100%;
  }
  .is-wide-sm {
    font-size: 87.5%;
  }
  .is-wide-xs {
    font-size: 75%;
  }
  .is-wide-xxs {
    font-size: 62.5%;
  }
}

.is-0,
.is-mobile-0 {
  flex: 1 0 0%;
}

.is-1,
.is-mobile-1 {
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
}

.is-2,
.is-mobile-2 {
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

.is-3,
.is-mobile-3 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
}

.is-4,
.is-mobile-4 {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
}

.is-5,
.is-mobile-5 {
  flex: 0 0 41.66667%;
  width: 41.66667%;
  max-width: 41.66667%;
}

.is-6,
.is-mobile-6 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}

.is-7,
.is-mobile-7 {
  flex: 0 0 58.33333%;
  width: 58.33333%;
  max-width: 58.33333%;
}

.is-8,
.is-mobile-8 {
  flex: 0 0 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%;
}

.is-9,
.is-mobile-9 {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
}

.is-10,
.is-mobile-10 {
  flex: 0 0 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
}

.is-11,
.is-mobile-11 {
  flex: 0 0 91.66667%;
  width: 91.66667%;
  max-width: 91.66667%;
}

.is-12,
.is-mobile-12 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

.is-full,
.is-mobile-full {
  flex: 0 1 100%;
  width: 100%;
}

.is-auto,
.is-mobile-auto {
  flex: 0 1 auto;
  width: auto;
}

@media screen and (min-width: 576px) {
  .is-fablet-0 {
    flex: 1 0 0%;
  }
  .is-fablet-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }
  .is-fablet-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }
  .is-fablet-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .is-fablet-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  .is-fablet-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }
  .is-fablet-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .is-fablet-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }
  .is-fablet-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
  .is-fablet-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .is-fablet-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  .is-fablet-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }
  .is-fablet-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .is-fablet-full {
    flex: 0 1 100%;
    width: 100%;
  }
  .is-fablet-auto {
    flex: 0 1 auto;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .is-tablet-0 {
    flex: 1 0 0%;
  }
  .is-tablet-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }
  .is-tablet-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }
  .is-tablet-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .is-tablet-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  .is-tablet-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }
  .is-tablet-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .is-tablet-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }
  .is-tablet-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
  .is-tablet-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .is-tablet-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  .is-tablet-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }
  .is-tablet-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .is-tablet-full {
    flex: 0 1 100%;
    width: 100%;
  }
  .is-tablet-auto {
    flex: 0 1 auto;
    width: auto;
  }
}

@media screen and (min-width: 992px) {
  .is-desktop-0 {
    flex: 1 0 0%;
  }
  .is-desktop-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }
  .is-desktop-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }
  .is-desktop-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .is-desktop-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  .is-desktop-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }
  .is-desktop-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .is-desktop-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }
  .is-desktop-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
  .is-desktop-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .is-desktop-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  .is-desktop-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }
  .is-desktop-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .is-desktop-full {
    flex: 0 1 100%;
    width: 100%;
  }
  .is-desktop-auto {
    flex: 0 1 auto;
    width: auto;
  }
}

@media screen and (min-width: 1200px) {
  .is-wide-0 {
    flex: 1 0 0%;
  }
  .is-wide-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }
  .is-wide-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }
  .is-wide-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .is-wide-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  .is-wide-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }
  .is-wide-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .is-wide-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }
  .is-wide-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
  .is-wide-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .is-wide-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  .is-wide-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }
  .is-wide-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .is-wide-full {
    flex: 0 1 100%;
    width: 100%;
  }
  .is-wide-auto {
    flex: 0 1 auto;
    width: auto;
  }
}

.is-space-xxl > *:not(:first-child) {
  margin-top: 28px;
}

.is-space-xl > *:not(:first-child) {
  margin-top: 24px;
}

.is-space-lg > *:not(:first-child) {
  margin-top: 20px;
}

.is-space > *:not(:first-child),
.is-space-md > *:not(:first-child) {
  margin-top: 16px;
}

.is-space-sm > *:not(:first-child) {
  margin-top: 12px;
}

.is-space-xs > *:not(:first-child) {
  margin-top: 8px;
}

.is-space-xxs > *:not(:first-child) {
  margin-top: 4px;
}

.is-space-top-xxl > * {
  margin-top: 28px;
}

.is-space-top-xl > * {
  margin-top: 24px;
}

.is-space-top-lg > * {
  margin-top: 20px;
}

.is-space-top > *,
.is-space-top-md > * {
  margin-top: 16px;
}

.is-space-top-sm > * {
  margin-top: 12px;
}

.is-space-top-xs > * {
  margin-top: 8px;
}

.is-space-top-xxs > * {
  margin-top: 4px;
}

.is-space-right-xxl > * {
  margin-right: 28px;
}

.is-space-right-xl > * {
  margin-right: 24px;
}

.is-space-right-lg > * {
  margin-right: 20px;
}

.is-space-right > *,
.is-space-right-md > * {
  margin-right: 16px;
}

.is-space-right-sm > * {
  margin-right: 12px;
}

.is-space-right-xs > * {
  margin-right: 8px;
}

.is-space-right-xxs > * {
  margin-right: 4px;
}

.is-space-bottom-xxl > * {
  margin-bottom: 28px;
}

.is-space-bottom-xl > * {
  margin-bottom: 24px;
}

.is-space-bottom-lg > * {
  margin-bottom: 20px;
}

.is-space-bottom > *,
.is-space-bottom-md > * {
  margin-bottom: 16px;
}

.is-space-bottom-sm > * {
  margin-bottom: 12px;
}

.is-space-bottom-xs > * {
  margin-bottom: 8px;
}

.is-space-bottom-xxs > * {
  margin-bottom: 4px;
}

.is-space-left-xxl > * {
  margin-left: 28px;
}

.is-space-left-xl > * {
  margin-left: 24px;
}

.is-space-left-lg > * {
  margin-left: 20px;
}

.is-space-left > *,
.is-space-left-md > * {
  margin-left: 16px;
}

.is-space-left-sm > * {
  margin-left: 12px;
}

.is-space-left-xs > * {
  margin-left: 8px;
}

.is-space-left-xxs > * {
  margin-left: 4px;
}

.is-margin-xxl {
  margin: 28px;
}

.is-margin-xl {
  margin: 24px;
}

.is-margin-lg {
  margin: 20px;
}

.is-margin,
.is-margin-md {
  margin: 16px;
}

.is-margin-sm {
  margin: 12px;
}

.is-margin-xs {
  margin: 8px;
}

.is-margin-xxs {
  margin: 4px;
}

.is-margin-top-xxl {
  margin-top: 28px;
}

.is-margin-top-xl {
  margin-top: 24px;
}

.is-margin-top-lg {
  margin-top: 20px;
}

.is-margin-top,
.is-margin-top-md {
  margin-top: 16px;
}

.is-margin-top-sm {
  margin-top: 12px;
}

.is-margin-top-xs {
  margin-top: 8px;
}

.is-margin-top-xxs {
  margin-top: 4px;
}

.is-margin-right-xxl {
  margin-right: 28px;
}

.is-margin-right-xl {
  margin-right: 24px;
}

.is-margin-right-lg {
  margin-right: 20px;
}

.is-margin-right,
.is-margin-right-md {
  margin-right: 16px;
}

.is-margin-right-sm {
  margin-right: 12px;
}

.is-margin-right-xs {
  margin-right: 8px;
}

.is-margin-right-xxs {
  margin-right: 4px;
}

.is-margin-bottom-xxl {
  margin-bottom: 28px;
}

.is-margin-bottom-xl {
  margin-bottom: 24px;
}

.is-margin-bottom-lg {
  margin-bottom: 20px;
}

.is-margin-bottom,
.is-margin-bottom-md {
  margin-bottom: 16px;
}

.is-margin-bottom-sm {
  margin-bottom: 12px;
}

.is-margin-bottom-xs {
  margin-bottom: 8px;
}

.is-margin-bottom-xxs {
  margin-bottom: 4px;
}

.is-margin-left-xxl {
  margin-left: 28px;
}

.is-margin-left-xl {
  margin-left: 24px;
}

.is-margin-left-lg {
  margin-left: 20px;
}

.is-margin-left,
.is-margin-left-md {
  margin-left: 16px;
}

.is-margin-left-sm {
  margin-left: 12px;
}

.is-margin-left-xs {
  margin-left: 8px;
}

.is-margin-left-xxs {
  margin-left: 4px;
}

.is-margin-vertical-xxl {
  margin-top: 28px;
  margin-bottom: 28px;
}

.is-margin-vertical-xl {
  margin-top: 24px;
  margin-bottom: 24px;
}

.is-margin-vertical-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.is-margin-vertical,
.is-margin-vertical-md {
  margin-top: 16px;
  margin-bottom: 16px;
}

.is-margin-vertical-sm {
  margin-top: 12px;
  margin-bottom: 12px;
}

.is-margin-vertical-xs {
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-margin-vertical-xxs {
  margin-top: 4px;
  margin-bottom: 4px;
}

.is-margin-horizontal-xxl {
  margin-right: 28px;
  margin-left: 28px;
}

.is-margin-horizontal-xl {
  margin-right: 24px;
  margin-left: 24px;
}

.is-margin-horizontal-lg {
  margin-right: 20px;
  margin-left: 20px;
}

.is-margin-horizontal,
.is-margin-horizontal-md {
  margin-right: 16px;
  margin-left: 16px;
}

.is-margin-horizontal-sm {
  margin-right: 12px;
  margin-left: 12px;
}

.is-margin-horizontal-xs {
  margin-right: 8px;
  margin-left: 8px;
}

.is-margin-horizontal-xxs {
  margin-right: 4px;
  margin-left: 4px;
}

.is-margin-minus-xxl {
  margin: -28px;
}

.is-margin-minus-xl {
  margin: -24px;
}

.is-margin-minus-lg {
  margin: -20px;
}

.is-margin-minus,
.is-margin-minus-md {
  margin: -16px;
}

.is-margin-minus-sm {
  margin: -12px;
}

.is-margin-minus-xs {
  margin: -8px;
}

.is-margin-minus-xxs {
  margin: -4px;
}

.is-margin-top-minus-xxl {
  margin-top: -28px;
}

.is-margin-top-minus-xl {
  margin-top: -24px;
}

.is-margin-top-minus-lg {
  margin-top: -20px;
}

.is-margin-top-minus,
.is-margin-top-minus-md {
  margin-top: -16px;
}

.is-margin-top-minus-sm {
  margin-top: -12px;
}

.is-margin-top-minus-xs {
  margin-top: -8px;
}

.is-margin-top-minus-xxs {
  margin-top: -4px;
}

.is-margin-right-minus-xxl {
  margin-right: -28px;
}

.is-margin-right-minus-xl {
  margin-right: -24px;
}

.is-margin-right-minus-lg {
  margin-right: -20px;
}

.is-margin-right-minus,
.is-margin-right-minus-md {
  margin-right: -16px;
}

.is-margin-right-minus-sm {
  margin-right: -12px;
}

.is-margin-right-minus-xs {
  margin-right: -8px;
}

.is-margin-right-minus-xxs {
  margin-right: -4px;
}

.is-margin-bottom-minus-xxl {
  margin-bottom: -28px;
}

.is-margin-bottom-minus-xl {
  margin-bottom: -24px;
}

.is-margin-bottom-minus-lg {
  margin-bottom: -20px;
}

.is-margin-bottom-minus,
.is-margin-bottom-minus-md {
  margin-bottom: -16px;
}

.is-margin-bottom-minus-sm {
  margin-bottom: -12px;
}

.is-margin-bottom-minus-xs {
  margin-bottom: -8px;
}

.is-margin-bottom-minus-xxs {
  margin-bottom: -4px;
}

.is-margin-left-minus-xxl {
  margin-left: -28px;
}

.is-margin-left-minus-xl {
  margin-left: -24px;
}

.is-margin-left-minus-lg {
  margin-left: -20px;
}

.is-margin-left-minus,
.is-margin-left-minus-md {
  margin-left: -16px;
}

.is-margin-left-minus-sm {
  margin-left: -12px;
}

.is-margin-left-minus-xs {
  margin-left: -8px;
}

.is-margin-left-minus-xxs {
  margin-left: -4px;
}

.is-margin-vertical-minus-xxl {
  margin-top: -28px;
  margin-bottom: -28px;
}

.is-margin-vertical-minus-xl {
  margin-top: -24px;
  margin-bottom: -24px;
}

.is-margin-vertical-minus-lg {
  margin-top: -20px;
  margin-bottom: -20px;
}

.is-margin-vertical-minus,
.is-margin-vertical-minus-md {
  margin-top: -16px;
  margin-bottom: -16px;
}

.is-margin-vertical-minus-sm {
  margin-top: -12px;
  margin-bottom: -12px;
}

.is-margin-vertical-minus-xs {
  margin-top: -8px;
  margin-bottom: -8px;
}

.is-margin-vertical-minus-xxs {
  margin-top: -4px;
  margin-bottom: -4px;
}

.is-margin-horizontal-minus-xxl {
  margin-right: -28px;
  margin-left: -28px;
}

.is-margin-horizontal-minus-xl {
  margin-right: -24px;
  margin-left: -24px;
}

.is-margin-horizontal-minus-lg {
  margin-right: -20px;
  margin-left: -20px;
}

.is-margin-horizontal-minus,
.is-margin-horizontal-minus-md {
  margin-right: -16px;
  margin-left: -16px;
}

.is-margin-horizontal-minus-sm {
  margin-right: -12px;
  margin-left: -12px;
}

.is-margin-horizontal-minus-xs {
  margin-right: -8px;
  margin-left: -8px;
}

.is-margin-horizontal-minus-xxs {
  margin-right: -4px;
  margin-left: -4px;
}

.is-margin-right-auto {
  margin-right: auto;
}

.is-margin-left-auto {
  margin-left: auto;
}

.is-margin-horizontal-auto {
  margin-right: auto;
  margin-left: auto;
}

.is-padding-xxl {
  padding: 28px;
}

.is-padding-xl {
  padding: 24px;
}

.is-padding-lg {
  padding: 20px;
}

.is-padding,
.is-padding-md {
  padding: 16px;
}

.is-padding-sm {
  padding: 12px;
}

.is-padding-xs {
  padding: 8px;
}

.is-padding-xxs {
  padding: 4px;
}

.is-padding-top-xxl {
  padding-top: 28px;
}

.is-padding-top-xl {
  padding-top: 24px;
}

.is-padding-top-lg {
  padding-top: 20px;
}

.is-padding-top,
.is-padding-top-md {
  padding-top: 16px;
}

.is-padding-top-sm {
  padding-top: 12px;
}

.is-padding-top-xs {
  padding-top: 8px;
}

.is-padding-top-xxs {
  padding-top: 4px;
}

.is-padding-right-xxl {
  padding-right: 28px;
}

.is-padding-right-xl {
  padding-right: 24px;
}

.is-padding-right-lg {
  padding-right: 20px;
}

.is-padding-right,
.is-padding-right-md {
  padding-right: 16px;
}

.is-padding-right-sm {
  padding-right: 12px;
}

.is-padding-right-xs {
  padding-right: 8px;
}

.is-padding-right-xxs {
  padding-right: 4px;
}

.is-padding-bottom-xxl {
  padding-bottom: 28px;
}

.is-padding-bottom-xl {
  padding-bottom: 24px;
}

.is-padding-bottom-lg {
  padding-bottom: 20px;
}

.is-padding-bottom,
.is-padding-bottom-md {
  padding-bottom: 16px;
}

.is-padding-bottom-sm {
  padding-bottom: 12px;
}

.is-padding-bottom-xs {
  padding-bottom: 8px;
}

.is-padding-bottom-xxs {
  padding-bottom: 4px;
}

.is-padding-left-xxl {
  padding-left: 28px;
}

.is-padding-left-xl {
  padding-left: 24px;
}

.is-padding-left-lg {
  padding-left: 20px;
}

.is-padding-left,
.is-padding-left-md {
  padding-left: 16px;
}

.is-padding-left-sm {
  padding-left: 12px;
}

.is-padding-left-xs {
  padding-left: 8px;
}

.is-padding-left-xxs {
  padding-left: 4px;
}

.is-padding-vertical-xxl {
  padding-top: 28px;
  padding-bottom: 28px;
}

.is-padding-vertical-xl {
  padding-top: 24px;
  padding-bottom: 24px;
}

.is-padding-vertical-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.is-padding-vertical,
.is-padding-vertical-md {
  padding-top: 16px;
  padding-bottom: 16px;
}

.is-padding-vertical-sm {
  padding-top: 12px;
  padding-bottom: 12px;
}

.is-padding-vertical-xs {
  padding-top: 8px;
  padding-bottom: 8px;
}

.is-padding-vertical-xxs {
  padding-top: 4px;
  padding-bottom: 4px;
}

.is-padding-horizontal-xxl {
  padding-right: 28px;
  padding-left: 28px;
}

.is-padding-horizontal-xl {
  padding-right: 24px;
  padding-left: 24px;
}

.is-padding-horizontal-lg {
  padding-right: 20px;
  padding-left: 20px;
}

.is-padding-horizontal,
.is-padding-horizontal-md {
  padding-right: 16px;
  padding-left: 16px;
}

.is-padding-horizontal-sm {
  padding-right: 12px;
  padding-left: 12px;
}

.is-padding-horizontal-xs {
  padding-right: 8px;
  padding-left: 8px;
}

.is-padding-horizontal-xxs {
  padding-right: 4px;
  padding-left: 4px;
}

.is-padding-safe {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}

.is-padding-top-safe {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.is-padding-right-safe {
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
}

.is-padding-bottom-safe {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.is-padding-left-safe {
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}

.is-padding-vertical-safe {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.is-padding-horizontal-safe {
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}

.is-overflow-hidden {
  overflow: hidden;
}

.is-overflow-scroll-x {
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.is-overflow-scroll-y {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.is-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-clearfix:after {
  content: "";
  display: block;
  clear: both;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  background-color: #F2F2F2;
  letter-spacing: .07em;
}

body {
  font-family: YakuHanJP, "Not Sans JP", sans-serif;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.section .section-header {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .section .section-header {
    border-top: 1px solid #dbdbdb;
    padding-top: 3rem;
  }
}

.section .section-footer {
  display: flex;
  padding-bottom: 2rem;
}

.section .section-footer.is-reverse {
  flex-direction: row-reverse;
}

.section.is-header {
  position: sticky;
  top: 0;
  height: 4rem;
  z-index: 2;
}

@media screen and (min-width: 992px) {
  .section.is-header {
    height: 5rem;
  }
}

.section.is-header > .inner {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.section.is-header > .inner.is-full {
  width: 100%;
}

.section.is-footer {
  margin-top: 8rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .section.is-footer {
    margin-top: 4rem;
  }
}

.section.is-footer-nav {
  text-align: center;
}

.section.is-footer-nav li {
  padding-bottom: 1rem;
}

.section.is-hero {
  display: flex;
  width: 100%;
  height: calc(100vh - 5rem);
}

@media screen and (max-width: 991px) {
  .section.is-hero {
    height: 55vh;
  }
}

.section.is-hero .main {
  width: calc(100vw - 5rem);
  padding: 0 5rem 3rem 0;
}

@media screen and (max-width: 991px) {
  .section.is-hero .main {
    width: 100%;
    padding: 0 1.5rem;
  }
}

.section.is-hero .scroll {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 5rem;
  height: 100%;
  writing-mode: vertical-rl;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}

@media screen and (max-width: 991px) {
  .section.is-hero .scroll {
    display: none;
  }
}

.section.is-hero .scroll::after {
  content: "";
  width: 1px;
  background-color: #333333;
  height: 6rem;
  margin-top: 1rem;
  animation: sdl 1.75s cubic-bezier(1, 0, 0, 1) infinite;
}

.section.is-about {
  margin-top: 3rem;
}

@media screen and (min-width: 992px) {
  .section.is-about {
    margin-top: 8rem;
  }
}

.section.is-works {
  padding-bottom: 5rem;
}

@media screen and (max-width: 991px) {
  .section.is-works {
    padding-bottom: 0;
  }
}

.section.is-works.is-topPage {
  margin-top: 8rem;
}

@media screen and (max-width: 991px) {
  .section.is-works.is-topPage {
    margin-top: 0;
  }
}

.section.is-works .inner > *:nth-child(2) {
  margin-top: 3rem;
}

.section.is-works .inner > *:nth-child(n + 3) {
  margin-top: 3rem;
}

@media screen and (min-width: 992px) {
  .section.is-works .inner > *:nth-child(n + 3) {
    margin-top: 15rem;
  }
}

@media screen and (max-width: 991px) {
  .section.is-blog .inner {
    padding: 0 1.5rem;
  }
}

.section.is-blog .inner > *:nth-child(n + 2) {
  margin-top: 3rem;
}

.section.is-page-hero.is-no-image {
  padding-top: 16rem;
}

@media screen and (max-width: 991px) {
  .section.is-page-hero.is-no-image {
    padding-top: 8rem;
  }
}

.section.is-breadcrumblist {
  margin-top: 3rem;
}

.section.is-flow {
  margin-top: 10rem;
}

.section.is-profile {
  margin-top: 10rem;
}

.section.is-contact .inner {
  padding: 0 8rem;
}

@media screen and (max-width: 991px) {
  .section.is-contact .inner {
    padding: 0 1.5rem;
  }
}

.section.is-pagination {
  padding-top: 8rem;
}

.section.is-desc {
  padding-top: 8rem;
}

@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

.grid.is-gap-xxxl {
  margin-left: -40px;
  margin-bottom: -40px;
}

.grid.is-gap-xxxl > .column {
  padding-left: 40px;
  padding-bottom: 40px;
}

.text.is-pj_primary {
  color: #37b0be;
}

.text.is-pj_dark_grey {
  color: #333333;
}

.text.is-pj_middle_grey {
  color: #c4eaee;
}

.text.is-pj_grey {
  color: #F2F2F2;
}

.text.is-pj_light_grey {
  color: #FFFFFF;
}

.button {
  padding: 1rem 2rem;
}

.button.is-outline {
  background-color: transparent;
}

@media screen and (max-width: 991px) {
  .button.is-nav {
    display: block;
    box-sizing: content-box;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    width: 20px;
    cursor: pointer;
    color: #333333;
  }
  .button.is-nav::before,
  .button.is-nav::after {
    content: '';
    display: block;
    height: 2px;
    background-color: currentColor;
    transform: translateY(5px);
    transition: 0.3s ease-in-out;
  }
  .button.is-nav::before {
    transform: translateY(-5px);
    box-shadow: 0 6px currentColor;
  }
  .is-active .button.is-nav {
    z-index: 1000;
    color: white;
  }
  .is-active .button.is-nav::before {
    transform: translateY(1px) rotate(45deg);
    box-shadow: none;
  }
  .is-active .button.is-nav::after {
    transform: translateY(-1px) rotate(-45deg);
  }
}

@media screen and (min-width: 992px) {
  .button.is-nav {
    display: none;
  }
}

.button.is-contact {
  min-width: 14rem;
}

@media screen and (max-width: 991px) {
  .button.is-contact {
    width: 100%;
  }
}

.table.is-melt {
  background-color: transparent;
}

.table.is-form th {
  width: 25%;
  padding: .75rem 0;
}

.table.is-form td {
  width: 75%;
  padding: .75rem 0;
}

.table.is-form input {
  width: 100%;
  border: none;
}

.table.is-form textarea {
  width: 100%;
  border: none;
}

@media screen and (max-width: 991px) {
  .table.is-form th {
    display: block;
    width: 100%;
    border-bottom: none;
  }
  .table.is-form td {
    display: block;
    width: 100%;
    border-bottom: none;
  }
}

.box.is-full img {
  width: 100%;
}

.nav {
  letter-spacing: 2px;
}

@media screen and (max-width: 991px) {
  .section.is-header .nav {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: left 0.5s;
  }
  .section.is-header .nav ul {
    font-weight: 700;
    text-align: center;
  }
  .section.is-header .nav li:not(:first-child) {
    margin-top: 40px;
  }
  html.is-active, .is-active body {
    overflow: hidden;
  }
  .is-active .form {
    display: none;
  }
  .is-active .section.is-header nav {
    left: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .nav ul {
    display: flex;
    align-items: center;
    color: #333333;
  }
  .nav li:not(:first-child) {
    margin-left: 23px;
  }
  .nav .button {
    width: 148px;
    border-color: #F2F2F2;
  }
}

.logo {
  width: 240px;
  height: 90px;
}

@media screen and (min-width: 992px) {
  .logo {
    width: 320px;
    height: 130px;
  }
}

.logo-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #37b0be;
  height: 100%;
  width: 100%;
  border-radius: 0 0 1rem;
}

.logo img, .logo svg {
  width: 12rem;
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 992px) {
  .logo img, .logo svg {
    width: 15rem;
  }
}

.logo svg {
  fill: white;
}

.logo p {
  margin-bottom: 6px;
  color: white;
  font-size: 62.5%;
  letter-spacing: 0.125rem;
}

@media screen and (min-width: 992px) {
  .logo p {
    margin-bottom: 12px;
    font-size: 75%;
  }
}

.logo-title {
  color: #333333;
  font-size: 75%;
  font-weight: 900;
  line-height: 1.375;
}

@media screen and (min-width: 992px) {
  .logo-title {
    font-size: 125%;
  }
}

.logo-desc {
  color: #c4eaee;
  font-size: 62.5%;
  line-height: 1.375;
}

@media screen and (min-width: 992px) {
  .logo-desc {
    font-size: 75%;
  }
}

.catch {
  flex: auto;
  align-self: flex-end;
  padding: 33px 0;
  background-color: rgba(255, 255, 255, 0.76);
}

@media screen and (min-width: 992px) {
  .catch {
    padding: 47px 0 44px;
  }
}

.catch-body {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  line-height: 1.25;
}

.catch img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.catch p {
  font-size: 87.5%;
  color: #37b0be;
  margin-bottom: 15px;
}

@media screen and (min-width: 992px) {
  .catch p {
    font-size: 100%;
    margin-bottom: 22px;
  }
}

/* IE対応 ->ろロゴ自体のサイズが変わらず失敗 */
@media (-ms-high-contrast: none), not all and (-ms-high-contrast: none) {
  .catch img {
    width: calc( (100vw - 17px) * 0.84);
    max-width: 637.78px;
    height: 10px;
  }
}

.cards {
  margin-top: 53px;
}

@media screen and (min-width: 992px) {
  .cards {
    margin-top: 75px;
  }
}

.cards > .grid > .column:not(:first-child) .card {
  margin-top: 67px;
}

@media screen and (min-width: 992px) {
  .cards > .grid > .column:not(:first-child) .card {
    margin-top: 0;
  }
}

.card {
  color: #333333;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card figure {
  height: 246px;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 992px) {
  .card figure {
    height: 360px;
  }
}

.card-title {
  font-size: 125%;
  font-weight: 700;
  line-height: 1.75;
  color: #333333;
  margin-top: 22px;
}

@media screen and (min-width: 992px) {
  .card-title {
    font-size: 30px;
    line-height: 1.375;
    margin-top: 33px;
  }
}

.card-desc {
  max-width: 264px;
  margin: 17px auto 0;
  font-size: 75%;
  line-height: 2;
}

@media screen and (min-width: 992px) {
  .card-desc {
    width: 78%;
    max-width: none;
    margin-top: 26px;
    margin-bottom: auto;
  }
}

.card .button {
  margin: 25px auto 0;
}

.card.is-office figure {
  background-image: url(../img/office.jpg);
}

.card.is-manage figure {
  background-image: url(../img/manage.jpg);
}

.card.is-research figure {
  background-image: url(../img/research.jpg);
}

.slider {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 0 1rem 1rem 1rem;
}

.slider-item {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  animation: anime 18s 0s infinite;
}

.slider-item:nth-of-type(2) {
  animation-delay: 6s;
}

.slider-item:nth-of-type(3) {
  animation-delay: 12s;
}

@keyframes anime {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 576px) {
  .media__imageWrapper {
    box-shadow: calc((100vw - 540px) / -2) 5rem #37b0be;
  }
}

@media screen and (min-width: 768px) {
  .media__imageWrapper {
    box-shadow: calc((100vw - 720px) / -2) 5rem #37b0be;
  }
}

@media screen and (min-width: 992px) {
  .media__imageWrapper {
    box-shadow: calc((100vw - 960px) / -2) 5rem #37b0be;
  }
}

@media screen and (min-width: 1200px) {
  .media__imageWrapper {
    box-shadow: calc((100vw - 1140px) / -2) 8rem #37b0be;
  }
}

.media__bodyWrapper {
  margin-left: -5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  z-index: 0;
}

@media screen and (max-width: 991px) {
  .media__bodyWrapper {
    margin-left: 0;
  }
}

.media__body {
  background-color: #FFFFFF;
  padding: 3rem;
  border-radius: 1rem;
}

@media screen and (max-width: 991px) {
  .media__body {
    margin: -2rem 1rem 0;
    padding: 2rem 1.5rem 1rem;
  }
}

.media:nth-child(2n + 1) .grid {
  flex-direction: row-reverse;
}

@media screen and (min-width: 576px) {
  .media:nth-child(2n + 1) .grid .media__imageWrapper {
    box-shadow: calc((100vw - 540px) / 2) 5rem #37b0be;
  }
}

@media screen and (min-width: 768px) {
  .media:nth-child(2n + 1) .grid .media__imageWrapper {
    box-shadow: calc((100vw - 720px) / 2) 5rem #37b0be;
  }
}

@media screen and (min-width: 992px) {
  .media:nth-child(2n + 1) .grid .media__imageWrapper {
    box-shadow: calc((100vw - 960px) / 2) 5rem #37b0be;
  }
}

@media screen and (min-width: 1200px) {
  .media:nth-child(2n + 1) .grid .media__imageWrapper {
    box-shadow: calc((100vw - 1140px) / 2) 8rem #37b0be;
  }
}

@media screen and (min-width: 992px) {
  .media:nth-child(2n + 1) .grid .media__bodyWrapper {
    margin-right: -5%;
  }
}

.list__item__link {
  display: block;
  color: #333333;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(40, 49, 53, 0.2);
  overflow: hidden;
}

.list__item__link:hover {
  opacity: 0.8;
}

.list__item__link img {
  display: block;
  width: 100%;
  height: auto;
}

.list__item__link .text__wrap {
  padding: 15px;
  box-sizing: border-box;
}

.textBg {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.textBg__wrapper {
  display: block;
}

.textBg__wrapper.is-active {
  color: #fff;
}

.textBg__wrapper.is-active .textBg::before {
  animation: bg 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
  background: #37b0be;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left center;
}

.textBg__inner {
  color: #fff;
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .textBg__inner {
    font-size: 1.75rem;
  }
}

@keyframes bg {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-5%);
  }
  30% {
    transform: scaleX(1) translateX(0);
  }
  100% {
    transform: scaleX(1) translateX(0);
  }
  30%, 100% {
    opacity: 1;
  }
}

.textList {
  display: flex;
  align-items: center;
  color: #333333;
}

.textList-item:not(:first-child) {
  margin-left: 23px;
}

.flow {
  border: 1px solid #37b0be;
}

.flow > li {
  padding: 30px;
}

.flow > li:not(:last-child) {
  border-bottom: 1px solid #37b0be;
  position: relative;
}

.flow > li:not(:last-child)::before,
.flow > li:not(:last-child)::after {
  content: "";
  border: solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.flow > li:not(:last-child)::before {
  border-width: 16px;
  border-top-color: #37b0be;
}

.flow > li:not(:last-child)::after {
  border-width: 15px;
  border-top-color: #F2F2F2;
}

.flow > li dl {
  margin: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .flow > li dl {
    flex-flow: column;
  }
}

.flow > li dl dt {
  width: 25%;
  color: #37b0be;
}

@media screen and (max-width: 991px) {
  .flow > li dl dt {
    width: 100%;
  }
}

.flow > li dl dt .icon {
  color: #fff;
  background: #37b0be;
  padding: .5rem 1rem;
  margin-bottom: 10px;
  border-radius: 1rem;
  display: block;
}

.flow > li dl dd {
  width: 75%;
  padding: 1rem 2rem;
}

@media screen and (max-width: 991px) {
  .flow > li dl dd {
    width: 100%;
    padding: 0;
  }
}

.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
}

.pagination li {
  display: inline;
  text-align: center;
}

.pagination a,
.pagination span {
  float: left;
  display: block;
  text-decoration: none;
  padding: 5px 12px;
  margin-left: -1px;
  border: 1px solid transparent;
  line-height: 1.5;
}

.pagination a.active {
  cursor: default;
}

.pagination a:active {
  outline: none;
}

.modal-3 a,
.modal span {
  margin-left: 3px;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
}

.modal-3 a:hover {
  color: #37b0be;
}

.modal-3 a[aria-current], .modal-3 a:active {
  color: #37b0be;
}

.badge {
  color: #F2F2F2;
  background: #37b0be;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumb li {
  display: inline;
  /*横に並ぶように*/
  list-style: none;
  font-weight: bold;
  /*太字*/
}

.breadcrumb li:after {
  /* >を表示*/
  content: '>';
  padding: 0 0.2em;
  color: #333333;
}

.breadcrumb li:last-child:after {
  content: '';
}

.breadcrumb li a {
  text-decoration: none;
  color: #333333;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}

.breadcrumb a.disabled {
  pointer-events: none;
  opacity: 0.65;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.is-space-xxxl > :not(:first-child) {
  margin-top: 3rem;
}

@media screen and (max-width: 991px) {
  .is-space-xxxl-mobile-tablet > :not(:first-child) {
    margin-top: 3rem;
  }
}

.is-space-xxxxl > :not(:first-child) {
  margin-top: 12rem;
}

@media screen and (max-width: 991px) {
  .is-space-xxxxl-mobile-tablet > :not(:first-child) {
    margin-top: 12rem;
  }
}

.is-padding-horizontal-xxxl {
  padding-right: 8rem;
  padding-left: 8rem;
}

@media screen and (max-width: 991px) {
  .is-padding-horizontal-xxxl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
